<template>
  <div class="toolbar-container h-100 position-relative">

    <div class="invisible">

      <!-- left to right -->

      <div :style="topLeftToolbarsStyle" class="h-100 w-100 d-flex position-absolute">  
        <template v-for="toolbar in topLeftToolbars">                
          <div :ref=toolbar.name>
            <slot 
            :name="toolbar.name"
            facing="down"
            >        
            </slot>        
          </div>
        </template>        
      </div>    

      <div :style="topRightToolbarsStyle" class="h-100 w-100 d-flex justify-content-end position-absolute">  
        <template v-for="toolbar in topRightToolbars">          
          <div :ref=toolbar.name>
            <slot 
            :name="toolbar.name"
            facing="down"
            >        
            </slot>          
          </div>
        </template>  
      </div>

      <div :style="rightTopToolbarsStyle" class="h-100 w-100 d-flex flex-column align-items-end position-absolute">  
        <template v-for="toolbar in rightTopToolbars">      
          <div :ref=toolbar.name>
            <slot 
            :name="toolbar.name"
            facing="left"
            >        
            </slot>    
          </div>
        </template>        
      </div>    

      <div :style="rightBottomToolbarsStyle" class="h-100 w-100 d-flex flex-column align-items-end justify-content-end position-absolute">  
        <template v-for="toolbar in rightBottomToolbars">      
          <div :ref=toolbar.name>
            <slot 
            :name="toolbar.name"
            facing="left"
            >        
            </slot>    
          </div>
        </template>        
      </div>    

      <div :style="bottomRightToolbarsStyle" class="h-100 w-100 d-flex align-items-end justify-content-end position-absolute">  
        <template v-for="toolbar in bottomRightToolbars">                
          <div :ref=toolbar.name>
            <slot 
            :name="toolbar.name"
            facing="up"
            >        
            </slot>        
          </div>
        </template>        
      </div>        

      <div :style="bottomLeftToolbarsStyle" class="h-100 w-100 d-flex align-items-end position-absolute">  
        <template v-for="toolbar in bottomLeftToolbars">                
          <div :ref=toolbar.name>
            <slot 
            :name="toolbar.name"
            facing="up"
            >        
            </slot>        
          </div>
        </template>        
      </div>        

      <div :style="leftBottomToolbarsStyle" class="h-100 w-100 d-flex flex-column justify-content-end position-absolute">  
        <template v-for="toolbar in leftBottomToolbars">      
          <div :ref=toolbar.name>
            <slot 
            :name="toolbar.name"
            facing="right"
            >        
            </slot>    
          </div>
        </template>        
      </div>    

      <div :style="leftTopToolbarsStyle" class="h-100 w-100 d-flex flex-column position-absolute">  
        <template v-for="toolbar in leftTopToolbars">      
          <div :ref=toolbar.name>
            <slot 
            :name="toolbar.name"
            facing="right"
            >        
          </slot>    
          </div>
        </template>        
      </div>    
    </div>

    <!-- effectve -->

    <div :style="topLeftToolbarsStyle" class="h-100 w-100 d-flex position-absolute">  
      <template v-for="toolbar in effectiveTopLeftToolbars">                
          <slot 
          :name="toolbar.name"
          facing="down"
          >        
          </slot>        
      </template>        
    </div>    

    <div :style="topRightToolbarsStyle" class="h-100 w-100 d-flex justify-content-end position-absolute">  
      <template v-for="toolbar in effectiveTopRightToolbars">          
          <slot 
          :name="toolbar.name"
          facing="down"
          >        
          </slot>          
      </template>  
    </div>

    <div :style="rightTopToolbarsStyle" class="h-100 w-100 d-flex flex-column align-items-end position-absolute">  
      <template v-for="toolbar in effectiveRightTopToolbars">      
          <slot 
          :name="toolbar.name"
          facing="left"
          >        
          </slot>    
      </template>        
    </div>    

    <div :style="rightBottomToolbarsStyle" class="h-100 w-100 d-flex flex-column align-items-end justify-content-end position-absolute">  
      <template v-for="toolbar in effectiveRightBottomToolbars">      
          <slot 
          :name="toolbar.name"
          facing="left"
          >        
          </slot>    
      </template>        
    </div>    

    <div :style="bottomRightToolbarsStyle" class="h-100 w-100 d-flex align-items-end justify-content-end position-absolute">  
      <template v-for="toolbar in effectiveBottomRightToolbars">                
          <slot 
          :name="toolbar.name"
          facing="up"
          >        
          </slot>        
      </template>        
    </div>        

    <div :style="bottomLeftToolbarsStyle" class="h-100 w-100 d-flex align-items-end position-absolute">  
      <template v-for="toolbar in effectiveBottomLeftToolbars">                
          <slot 
          :name="toolbar.name"
          facing="up"
          >        
          </slot>        
      </template>        
    </div>        

    <div :style="leftBottomToolbarsStyle" class="h-100 w-100 d-flex flex-column justify-content-end position-absolute">  
      <template v-for="toolbar in effectiveLeftBottomToolbars">      
          <slot 
          :name="toolbar.name"
          facing="right"
          >        
          </slot>    
      </template>        
    </div>    

    <div :style="leftTopToolbarsStyle" class="h-100 w-100 d-flex flex-column position-absolute">  
      <template v-for="toolbar in effectiveLeftTopToolbars">              
        <slot 
        :name="toolbar.name"
        facing="right"
        >        
        </slot>            
      </template>        
    </div>    
  </div>

</template>

<script>
export default {              
  name: "Container",          
  props: {         
    paddingTopLeftWrap: {
      type: Number,
      default: 0
    },
    paddingTopRightWrap: {
      type: Number,
      default: 0
    },    
    paddingBottomRightWrap: {
      type: Number,
      default: 0
    },
    paddingRightBottomWrap: {
      type: Number,
      default: 0
    },    
    paddingTop: {
      type: Number,
      default: 0
    },    
    paddingRight: {
      type: Number,
      default: 0
    },
    paddingBottom: {
      type: Number,
      default: 0
    },
    paddingLeft: {
      type: Number,
      default: 0
    },    
    paddingTopLeft: {
      type: Number,
      default: 0
    },
    paddingTopRight: {
      type: Number,
      default: 0
    },    
    paddingRightTop: {
      type: Number,
      default: 0
    },
    paddingRightBottom: {
      type: Number,
      default: 0
    },    
    paddingBottomRight: {
      type: Number,
      default: 0
    },
    paddingBottomLeft: {
      type: Number,
      default: 0
    },    
    paddingLeftBottom: {
      type: Number,
      default: 0
    },    
    paddingLeftTop: {
      type: Number,
      default: 0
    },    
    minToolbarGap: {      
      type: Number,
      default: 0
    },
    topLeftToolbars: {
      type: Array,  
      default() { return [] }                
    },
    topRightToolbars: {
      type: Array,  
      default() { return [] }                
    },
    rightTopToolbars: { 
      type: Array,  
      default() { return [] }                
    },    
    rightBottomToolbars: { 
      type: Array,  
      default() { return [] }                
    },
    bottomRightToolbars: {
      type: Array,  
      default() { return [] }                  
    },
    bottomLeftToolbars: {
      type: Array,  
      default() { return [] }                
    },
    leftBottomToolbars: {
      type: Array,  
      default() { return [] }                
    },
    leftTopToolbars: {
      type: Array,  
      default() { return [] }                
    }    
  }, 
  data() {
    return {
      resizeObserver: null,
      effectiveTopLeftToolbars: [],
      effectiveTopRightToolbars: [],
      effectiveRightTopToolbars: [],
      effectiveRightBottomToolbars: [],
      effectiveBottomRightToolbars: [],
      effectiveBottomLeftToolbars: [],
      effectiveLeftBottomToolbars: [],
      effectiveLeftTopToolbars: [],            
      processUpdate: true
    }  
  },   
  mounted() {

    this.resizeObserver = new ResizeObserver(resizables => {                                  
      this.calcEffectiveToolbars();                        
    })
    
    this.resizeObserver.observe(this.$el);        
  },  
  updated() {        

    if (this.processUpdate) {
      this.processUpdate = false;
      this.calcEffectiveToolbars();                        

      this.$nextTick(() => {
        this.processUpdate = true;
      })
    }      
  },
  beforeUnmount() {        
    this.resizeObserver.unobserve(this.$el);
  },
  computed: {        
    topLeftToolbarsStyle() {                  
      return this.style(0, 
                        0, 
                        0,
                        this.paddingTopLeft + (this.effectiveLeftTopToolbars.length > 0 || this.effectiveLeftBottomToolbars.length > 0  ? this.paddingTopLeftWrap : 0));
    },
    topRightToolbarsStyle() {            
      return this.style(0, 
                        this.paddingTopRight + (this.effectiveLeftTopToolbars.length > 0  || this.effectiveRightTopToolbars.length > 0 ? this.paddingTopRightWrap: 0),
                        0,
                        0);      
    },
    rightTopToolbarsStyle() {            

      return this.style(this.paddingRightTop + (this.effectiveTopLeftToolbars.length > 0 || this.effectiveTopRightToolbars.length > 0 ? this.paddingTopRightWrap: 0), 
                        0,
                        0,
                        0);            
    },
    rightBottomToolbarsStyle() {      

      return this.style(0, 
                        0,
                        this.paddingRightBottom + (this.effectiveBottomLeftToolbars.length > 0 || this.effectiveBottomRightToolbars.length > 0 ? this.paddingRightBottomWrap: 0),
                        0);            
    },

    bottomRightToolbarsStyle() {      
      
      return this.style(0, 
                        this.paddingBottomRight + (this.effectiveRightTopToolbars.length > 0 || this.effectiveRightBottomToolbars.length > 0 ? this.paddingRightBottomWrap: 0),
                        0,
                        0);            
    },

    bottomLeftToolbarsStyle() {      

      return this.style(0, 
                        0,
                        0,
                        this.paddingBottomLeft + (this.effectiveLeftTopToolbars.length > 0 || this.effectiveLeftBottomToolbars.length > 0 ? this.paddingBottomLeftWrap: 0));            
    },

    leftBottomToolbarsStyle() {      

      return this.style(0, 
                        0,
                        this.paddingLeftBottom + (this.effectiveBottomLeftToolbars.length > 0 || this.effectiveBottomRightToolbars.length > 0 ? this.paddingBottomLeftWrap: 0),            
                        0);
    },

    leftTopToolbarsStyle() {      

      return this.style(this.paddingLeftTop + (this.effectiveTopLeftToolbars.length > 0 || this.effectiveTopRightToolbars.length > 0 ? this.paddingTopLeftWrap: 0),  
                        0,
                        0,
                        0);      
    },
  },

  methods: {    

    style(paddingTop = 0, paddingRight = 0, paddingBottom = 0, paddingLeft = 0) {
      return {
        'padding-top': this.paddingTop + paddingTop + "px",
        'padding-right': this.paddingRight + paddingRight + "px",
        'padding-bottom': this.paddingBottom + paddingBottom + "px",
        'padding-left': this.paddingLeft + paddingLeft + "px",
        'gap': this.minToolbarGap ? + this.minToolbarGap + 'px' : undefined
      }
    },    
    
    getToolbarsWidth(toolbars) {

      let size = toolbars.reduce((total, toolbar) => {       
        let toolbarEl = this.$refs[toolbar.name][0];
        return toolbarEl.clientWidth + total; 
      }, 0)          

      if (toolbars.length > 1) {
        size += this.minToolbarGap * (toolbars.length - 1);
      }     

      return size;
    },

    getTopToolbarsWidth(toolbars) {
      return this.getToolbarsWidth(toolbars) + this.paddingLeft + this.paddingRight + this.paddingTopLeft + this.paddingTopRight;
    },

    calcEffectiveToolbars() {      

      // function needs expanding to handle other dimensions.

      let containerWidth = this.$el.clientWidth;       

      this.effectiveTopLeftToolbars = this.topLeftToolbars.slice();
      this.effectiveTopRightToolbars = this.topRightToolbars.slice();
      this.effectiveRightTopToolbars = this.rightTopToolbars.slice();
      this.effectiveRightBottomToolbars = this.effectiveRightBottomToolbars.slice();
      this.effectiveBottomRightToolbars = this.effectiveBottomRightToolbars.slice();
      this.effectiveBottomLeftToolbars = this.effectiveBottomLeftToolbars.slice();
      this.effectiveLeftBottomToolbars = this.effectiveLeftBottomToolbars.slice();
      this.effectiveLeftTopToolbars = this.effectiveLeftTopToolbars.slice();

      let toolbars = [...this.topLeftToolbars, ...this.topRightToolbars];      
      let size = this.getTopToolbarsWidth(toolbars);           

      for (let i = this.effectiveTopRightToolbars.length - 1; i >= 0; i--) {

        if (size <= containerWidth) {
          break;
        }

        let toolbar = this.effectiveTopRightToolbars[i];
        
        if (toolbar.fixed) {
          break;
        }
                
        this.effectiveTopRightToolbars.pop();
        this.effectiveRightTopToolbars = [toolbar, ...this.effectiveRightTopToolbars];

        toolbars = [...this.effectiveTopLeftToolbars, ...this.effectiveTopRightToolbars];
        size = this.getTopToolbarsWidth(toolbars);                  

        size += this.paddingTopRightWrap;
      }
    }
  }
}

</script>
