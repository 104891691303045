<template>    
  <div class="d-flex"      
  :class="{'align-items-end': facing === 'up' || facing === 'left', 'flex-column': facing === 'left' || facing === 'right'}">
    <slot :facing="facing"></slot>
  </div>  
</template>

<script>

export default {               
  name: "Toolbar",           
  props: {
    facing: {
      type: String,
      default: "down"            
    },
  },      
}

</script>

<style lang="scss" scoped>
@import "../../../variables";

.toolbar {
  display: inherit;
  align-items: inherit;
  flex-direction: inherit;   
}

</style>