<template>        
  <div v-if="loaded" class="featureProperties h-100">     
    <portal to="menus/top+createFeature">
      <div class="d-flex">
        <ToggleButton     
        v-b-popover.hover.bottom="createFeatureToolTip"
        :height="48" 
        :width="36" 
        selectionSide="top" 
        :selected="createActive" 
        @clicked="$emit('activate')">        
        <template v-if="createGeometryType === 'polygon'">
          <font-awesome-icon :icon="toolbarButtons.find(button => button.value === createGeometryType).icon" transform="right-2 grow-5"/>                       
        </template>        
        <template v-else-if="createGeometryType === 'point'">
          <font-awesome-icon :icon="toolbarButtons.find(button => button.value === createGeometryType).icon" transform="shrink-2"/>                       
        </template>                  
        <template v-else>
          <font-awesome-icon :icon="toolbarButtons.find(button => button.value === createGeometryType).icon" transform="grow-3"/>                       
        </template>                  
        </ToggleButton>
        <ToggleButton 
        v-b-popover.hover.bottom="'Feature shape'"
        :menuItems="menuItems" :height="48" :width="24" selectionSide="top" subMenuName="sketch" @openMenuItem="$emit('openMenuItem', $event)" @closeMenuItem="$emit('closeMenuItem', $event)">					
          <font-awesome-icon icon="caret-down" transform="left-4"/>					
        </ToggleButton>
      </div>  
    </portal>    
    <portal :to="'menus/' + menuName">
      <div class="sketch h-100 d-flex flex-column">        
        <SliderHeader 
        text="CREATE FEATURE" 
        :menuName="menuName"
        @showHelp="$emit('showHelp','sketch')"
        @openMenuItem="$emit('openMenuItem', $event)" 
        @closeMenuItem="$emit('closeMenuItem', $event)">
        </SliderHeader>
        <div class="toolbar w-100">
          <Toolbar :items="toolbarButtons" :value="createGeometryType" @input="geometryTypeChanged"></Toolbar>
        </div>
        <div class="mainContent d-flex flex-grow-1 flex-column overflow-auto">          
        </div>                      
      </div>
    </portal>    
    <template v-if="feature">
      <template v-if="hasLayerProperties(feature.layer)">
        <portal to="propertiesMenuBarLeft">
          <button                                       
          class="bg-transparent border-0 p-0 d-flex mx-1"                       
          v-b-popover.hover.top="'Layer properties (metadata)'"          
          @click="$emit('layerInfo', feature.layer)">
            <font-awesome-icon                                                     
            transform="grow-3"
            icon="info-circle"/>
          </button>                 
        </portal>
      </template>
      <portal to="propertiesMenuBar">
        <div class="d-flex align-items-center ml-2 mr-1">                  
          <template v-if="isMBCreated">
            <button
            id="deleteButton"
            class="d-flex align-items-center mr-1"                        
            @click="deleteFeature"
            >
              <font-awesome-icon icon="trash-alt"/>
            </button>          
          </template>
          <template v-if="canBookmark">
            <button
            class="d-flex align-items-center mr-1"  
            v-b-popover.hover.top="'Pin Bookmark'"                                         
            @click="addBookmark"
            >
                <font-awesome-icon :icon="['fas', 'bookmark']"/>
            </button>
          </template>
          <template v-if="isMBCreated">
            <button
            id="useAsDefaultButton"
            class="d-flex align-items-center mr-1"  
            :class="{inactive: !useAsDefaultEnabled}"
            v-b-popover.hover.top="useAsDefaultToolTip"
            @click="useAsDefault"
            >
              <font-awesome-icon :icon="['far', 'sliders-h']"></font-awesome-icon>
            </button>                
          </template>
          <button
          class="d-flex align-items-center"  
          v-b-popover.hover.top="'Goto (retain scale)'"                                         
          @click="goto"
          >
          <font-awesome-icon :icon="['far', 'dot-circle']"/>        
          </button>  
          <template v-if="canZoomToFit(feature)">
            <button
            class="d-flex align-items-center mr-1"  
            v-b-popover.hover.top="'Goto and zoom to fit'"
            @click="gotoAndZoomToFit"
            >
              <font-awesome-icon :icon="['far', 'search-location']"/>
            </button>  
          </template>  
        </div>
      </portal>      
      <div class="h-100 d-flex flex-column mt-1">              
        <template v-if="showGeometry">
          <div class="propertyGrid rounded mt-1 mb-2 py-2 geometryGrid centered">                        
            <template v-if="showLocation">
              <div class="pl-2 d-flex">            
                <template v-if="showCaret">
                  <button
                  class="d-flex align-items-center"              
                  @click="$emit('geometryExpandedChange', !geometryExpanded)"
                  >
                    <font-awesome-icon transform="grow-4" :icon="geometryExpanded ? 'caret-down' : 'caret-right'"/>
                  </button>                          
                </template>              
                <span class="ml-auto px-2 label">
                  Location
                </span>              
              </div>
              <div 
                @mouseover="locationHover = true" 
                @mouseleave="locationHover = false" 
                class="ml-2 min-width-0 d-flex">            
                <span class="text-ellipsis overflow-hidden min-width-0">{{this.getLocationString(this.feature, coordinateMode)}}</span>
                <template v-if="locationEyeVisible">
                  <div class="ml-auto mr-2 d-flex">                                    
                    <button              
                    class="d-flex align-items-center"  
                    v-b-popover.hover.left="getToolTip('Location')"
                    @click="locationVisibleChanged"
                    :class="{'invisible' : !locationHover && !isMBCreated && hasMouse}"                    
                    >
                      <font-awesome-icon transform="grow-3" :icon="activeLocationVisibleOnMap ? 'eye' : ['far', 'eye']"/>
                    </button>                                            
                  </div>
                </template>
              </div>
            </template>
            <template v-if="(isMBCreated || geometryExpanded) && this.getGeometryType(feature) !== 'text'">
              <template v-if="feature.geometry.type === 'polyline'">
                <div class="px-2 label">
                Length
                </div>
                <div 
                @mouseover="lengthHover = true" 
                @mouseleave="lengthHover = false" 
                class="ml-2 min-width-0 d-flex align-items-center">            
                  <span class="text-ellipsis overflow-hidden min-width-0">{{this.getLengthString(this.feature.geometry, activeLengthUnit)}}</span>
                  <DropdownSelect
                  @input="lengthUnitChanged" 
                  :value="activeLengthUnit" 
                  :items="lengthUnitItems">)
                  </DropdownSelect>                  
                  <div class="ml-auto mr-2 d-flex">  
                    <template v-if="sideLengthsEyeVisible">     
                      <button
                      class="d-flex align-items-center mr-1"  
                      v-b-popover.hover.top="getToolTip('Length of sides')"
                      @click="sideLengthsVisibleOnMapChanged"
                      :class="{'invisible' : !lengthHover && !isMBCreated && hasMouse}"
                      >
                        <font-awesome-layers>
                          <font-awesome-icon transform="grow-3" :icon="activeSideLengthsVisibleOnMap ? 'eye' : ['far', 'eye']"/>                        
                          <font-awesome-icon transform="right-14 up-8 shrink-6" icon="asterisk" />                        
                        </font-awesome-layers>             
                      </button>
                    </template>                       
                    <template v-if="lengthEyeVisible">                                  
                      <button
                      class="d-flex align-items-center"  
                      v-b-popover.hover.top="getToolTip('Length')"
                      @click="lengthVisibleChanged"
                      :class="{'invisible' : !lengthHover && !isMBCreated && hasMouse}"
                      >
                        <font-awesome-icon transform="grow-3" :icon="activeLengthVisibleOnMap ? 'eye' : ['far', 'eye']"/>
                      </button>                                                                                        
                    </template>                   
                  </div>
                </div>
              </template>      
              <template v-if="feature.geometry.type === 'polygon'">
                <div class="px-2 label">
                Perimeter Length
                </div>
                <div 
                @mouseover="lengthHover = true" 
                @mouseleave="lengthHover = false" 
                class="ml-2 d-flex min-width-0 align-items-center">            
                  <span class="text-ellipsis overflow-hidden min-width-0">{{this.getLengthString(this.feature.geometry, activeLengthUnit)}}</span>
                  <DropdownSelect                
                  @input="lengthUnitChanged" 
                  :value="activeLengthUnit" 
                  :items="lengthUnitItems">
                  </DropdownSelect>
                  <div class="ml-auto mr-2 d-flex">              
                     <template v-if="sideLengthsEyeVisible">     
                      <button
                      class="d-flex align-items-center mr-1"  
                      v-b-popover.hover.left="getToolTip('Length of sides')"
                      @click="sideLengthsVisibleOnMapChanged"
                      :class="{'invisible' : !lengthHover && !isMBCreated && hasMouse}"
                      >
                        <font-awesome-layers>
                          <font-awesome-icon transform="grow-3" :icon="activeSideLengthsVisibleOnMap ? 'eye' : ['far', 'eye']"/>                        
                          <font-awesome-icon transform="right-14 up-8 shrink-6" icon="asterisk" />                        
                        </font-awesome-layers>             
                      </button>
                    </template>
                    <template v-if="lengthEyeVisible">                        
                      <button
                      class="d-flex align-items-center"  
                      v-b-popover.hover.left="getToolTip('Perimeter Length')"
                      @click="lengthVisibleChanged"
                      :class="{'invisible' : !lengthHover && !isMBCreated && hasMouse}"
                      >
                        <font-awesome-icon transform="grow-3" :icon="activeLengthVisibleOnMap ? 'eye' : ['far', 'eye']"/>
                      </button>                                                            
                    </template>                   
                  </div>
                </div>               

                <div class="px-2 label">Area</div>
                  <div 
                  @mouseover="areaHover = true" 
                  @mouseleave="areaHover = false" 
                  class="ml-2 min-width-0 d-flex align-items-center">            
                  <span class="text-ellipsis overflow-hidden min-width-0">{{this.getAreaString(this.feature, activeAreaUnit)}}</span>
                  <DropdownSelect
                  @input="areaUnitChanged" 
                  :value="activeAreaUnit" 
                  :items="areaUnitItems">)
                  </DropdownSelect>
                  <template v-if="areaEyeVisible">    
                    <div class="ml-auto mr-2 d-flex">                  
                      <button
                      class="d-flex align-items-center"  
                      v-b-popover.hover.left="getToolTip('Area')"
                      @click="areaVisibleChanged"
                      :class="{'invisible' : !areaHover && !isMBCreated && hasMouse}"
                      >
                        <font-awesome-icon transform="grow-3" :icon="activeAreaVisibleOnMap ? 'eye' : ['far', 'eye']"/>
                      </button>                                          
                    </div>
                  </template>                                                         
                </div>
              </template>                                     
            </template>
          </div>          
        </template>
        <div class="h-100 overflow-auto">            
          <template v-if="feature.type !== 'annotationEnd'">
            <div class="propertyGrid best-fit mr-2">          
              <template v-for="field in customFields">                        
                <div                 
                :key="'key' + field.name" class="px-2 label">{{field.alias}}</div>                
                <div 
                @mouseover="$set(attributesHover, field.name, true)" 
                @mouseleave="$set(attributesHover, field.name, false)" 
                :key="'value' + field.name" class="d-flex min-width-0 ml-2">
                  <PasteableBoxes 
                  fullWidth
                  :commitOnClickAway="true"
                  placeHolder="name me..."
                  class="min-width-0 flex-grow-1"                  
                  :canEdit="field.canEdit === true"
                  :key="'value' + field.name"                                  
                  :value="getValue(field)"
                  @input="attributeChanged(field, $event)"
                  >
                  </PasteableBoxes>              
                  <button
                  @mouseover="$set(attributesHover, field.name, true)" 
                  @mouseleave="$set(attributesHover, field.name, false)" 
                  :class="{'invisible' : !attributesHover[field.name] && !isMBCreated && hasMouse}"
                  class="d-flex align-items-center ml-auto"  
                  v-b-popover.hover.left="getToolTip(field.alias)"
                  @click="customFieldVisibleOnMapChanged(field)"
                  >
                    <font-awesome-icon transform="grow-3" :icon="activeCustomFieldVisibleOnMap(field) ? 'eye' : ['far', 'eye']"/>
                  </button>                                            
                </div>
              </template>
            </div>
          </template>         
          <template v-if="isMBCreated">
            <template v-if="feature.annotation">              
              <SymbolProperties              
              ref="textSymbolProperties"    
              class="flex-grow-1 mt-2 mx-2"                                      
              noAngle
              noOpacity
              :textRowCount="8"
              :symbol="feature.annotation.label.symbol">              
              </SymbolProperties>
            </template>              
            <SymbolProperties                            
            class="flex-grow-1 mt-2 mx-2"    
            :mustHaveFillOrBorder="feature.type !== 'annotationEnd'"            
            :noOpacity="feature.type === 'annotationEnd'"
            :noStyle="feature.type === 'annotationEnd'"
            :noFillOnOff="feature.type === 'annotationEnd'"
            :noOutlineOnOff="feature.type === 'annotationEnd'"
            :noOutlineStyle="feature.type === 'annotationEnd'"
            :noOutlineOpacity="feature.type === 'annotationEnd'"
            :symbol="feature.symbol">            
            </SymbolProperties>                      
          </template> 
        </div>                
      </div>                    
    </template>    
  </div>  
</template>           

<script>

import Moment from 'moment';
import { loadModules } from "esri-loader";
import Toolbar from "./shared/Toolbar/Toolbar.vue";
import ToggleButton from "./menus/ToggleButton.vue"
import SliderHeader from './shared/SliderHeader.vue';
import SliderFooter from './shared/SliderFooter.vue';
import PasteableBoxes from './shared/PasteableBoxes.vue';
import SymbolProperties from './SymbolProperties.vue'; 
import DropdownSelect from "./shared/DropdownSelect.vue";
import { default as FeatureMixin } from "./FeatureMixin.vue";

export default {  
  components: { Toolbar,  ToggleButton, SliderHeader, SliderFooter, SymbolProperties, PasteableBoxes, DropdownSelect },   
  mixins: [ FeatureMixin ],
  props: {            
    geometryExpanded: {
      type: Boolean
    },    
    feature: {
      type: Object,      
    },     
    locationVisibleOnMap: {
      type: Boolean
    },
    lengthVisibleOnMap: {
      type: Boolean
    },    
    sideLengthsVisibleOnMap: {
      type: Boolean
    },    
    areaVisibleOnMap: {
      type: Boolean
    },
    areaUnit: {
      type: String,
    },      
    lengthUnit: {
      type: String,
    },
    coordinateMode: {
      type: String,
      required: true
    },
    menuItems: {
			type: Object,
		},   
		createActive: {
			type: Boolean,
			required: true
		},        
    createGeometryType: {
      type: String      
    },      
    useAsDefaultEnabled: {
      Type: Boolean
    },
  },   
  data() {
    return {                                              
      loaded: false,
      agsGeometryEngine: null,
      locationHover: false,      
      lengthHover: false,      
      areaHover: false,      
      attributesHover: {},      
      lengthUnitItems: [],
      menuName: "sketch", 			            
      defaultRectangleFeature: null,
      rowCounter: 0,
      toolbarButtons:[          
          { text:"", icon: ['fas', 'circle'], toolTip: "Point", iconClass:"fa-xs", pressed:false, value: "point" },
          { text:"", icon: ['fas', 'wave-sine'], toolTip: "Line", iconClass:"", pressed:false, value: "polyline" },
          { text:"", icon: ['fal', 'draw-polygon'], toolTip: "Polygon", iconClass:"", transform:"grow-3", pressed:false, value: "polygon" } ,
          { text:"", icon: ['far', 'circle'], toolTip: "Circle", iconClass:"", pressed:false, value: "circle" },
          { text:"", icon: ['far', 'square'], toolTip: "Rectangle", iconClass:"", pressed:false, value: "rectangle" },          
      ],        
    }      
  }, 
  async created() {    
    let res = await loadModules(["esri/geometry/geometryEngine"]);        
    this.agsGeometryEngine = res[0];    
    this.loaded = true;        

    this.lengthUnitItems = [      
      {text: "ft (Feet)", selectedText: "ft", value: "feet" },
      {text: "yd (Yards)", selectedText: "yd", value: "yards" },
      {text: "m (Metres)", selectedText: "m", value: "meters" },
      {text: "km (Kilometres)", selectedText: "km", value: "kilometers" },
      {text: "mi (Miles)", selectedText: "mi", value: "miles" }
    ];            

    this.areaUnitItems = [      
      {text: "ft² (Square Feet)", selectedText: "ft²", value: "square-feet" },
      {text: "yd² (Square Yards)", selectedText: "yd²", value: "square-yards" },
      {text: "m² (Square Metres)", selectedText: "m²", value: "square-meters" },
      {text: "km² (Square Kilometres)", selectedText: "km²", value: "square-kilometers" },
      {text: "mi² (Square Miles)", selectedText: "mi²", value: "square-miles" },
      {text: "acre (Acres)", selectedText: "acre", value: "acres" },
      {text: "ha (Hectares)", selectedText: "ha", value: "hectares" }      
    ];            
  },
  computed: {               

    createFeatureToolTip() {
      let tooltip = "Create ";

      if (this.createGeometryType == "point") {
        tooltip += "point"
      } 

      if (this.createGeometryType == "polyline") {
        tooltip += "line"
      } 

      if (this.createGeometryType === "rectangle") {
        tooltip += "rectangular"
      }

      if (this.createGeometryType === "circle") {
        tooltip += "circular"
      }

      if (this.createGeometryType === "polygon") {
        tooltip += "polygon"
      }

      return tooltip += " feature"
    },

    useAsDefaultToolTip() {
      let tip = "Always use these settings when creating a new ";
      if (this.feature.type === "measurement") {
        tip += "measurement";
      } else if (this.feature.type === "annotationEnd") {
        tip += "label";
      } else if (this.feature.type === "userFeature") {
        if (this.getGeometryType(this.feature) === "point") {
          tip += "point"
        }

        if (this.getGeometryType(this.feature) === "polyline") {
          tip += "line"
        }

        if (this.getGeometryType(this.feature) === "polygon") {
          tip += "polygon, circular, or rectangular"
        }

        tip += " feature";
      }

      return tip;
    },

    locationEyeVisible() {
      
      if ((this.feature.type === "measurement") &&
          (this.feature.locationVisibleOnMap) &&
          (!this.feature.lengthVisibleOnMap) &&
          (!this.feature.sideLengthsVisibleOnMap) && 
          (!this.feature.areaVisibleOnMap)) {
            return false;
      }

      return true;
    },

    lengthEyeVisible() {
      
      if ((this.feature.type === "measurement") &&
          (!this.feature.locationVisibleOnMap) &&
          (this.feature.lengthVisibleOnMap) &&
          (!this.feature.sideLengthsVisibleOnMap) && 
          (!this.feature.areaVisibleOnMap)) {
            return false;
      }

      if (this.getGeometryType(this.feature) === "polyline") {        
        if (this.feature.geometry.paths.length > 0 && this.feature.geometry.paths[0].length < 3) {
          return false;
        }
      }

      return true;
    },

    sideLengthsEyeVisible() {
      if ((this.feature.type === "measurement") &&
          (!this.feature.locationVisibleOnMap) &&
          (!this.feature.lengthVisibleOnMap) &&
          (this.feature.sideLengthsVisibleOnMap) && 
          (!this.feature.areaVisibleOnMap)) {
            return false;
      } else if (this.feature.type === "system") {
        return false;
      }

      if (this.getGeometryType(this.feature) === "point" || this.getGeometryType(this.feature) === "circle") {
        return false;
      }

      return true;
    },

    areaEyeVisible() {
      
       if ((this.feature.type === "measurement") &&
          (!this.feature.locationVisibleOnMap) &&
          (!this.feature.lengthVisibleOnMap) &&
          (!this.feature.sideLengthsVisibleOnMap) && 
          (this.feature.areaVisibleOnMap)) {
            return false;
      }

      return true;
    },    
    
    canBookmark() {
      return ((this.feature.type !== "measurement") && (this.feature.type !== "annotationEnd"))
    },

    showLocation() {
      
      if (this.feature.type === "annotationEnd") {
        return false;
      }

      if (!this.feature.geometry || this.getGeometryType(this.feature) === "polyline" && this.feature.type !== "system") {
        return false;
      }

      return true;
    },

    showGeometry() {
      if (this.feature.type !== "annotationEnd") {
        return true;
      }

      return false;
    },

    showCaret() {
      if (!this.feature.geometry || this.getGeometryType(this.feature) === "point") {
        return false;
      }     

      if (this.isMBCreated) {
        return false;
      }
      
      return true;
    },

    customFields() {

      if (!this.feature || !this.feature.layer) {
        return [];
      }

      let geoField = this.feature.layer.fields.find(field => field.type === "esriFieldTypeGeometry");
      let geoFieldName = geoField ? geoField.name : null;

      return this.feature.layer.fields.filter(field => {        
        if ((field.name === this.feature.layer.objectIdPropertyName) && (this.feature.type === 'userFeature')) {
          return false;
        }      

        if (field.name === geoFieldName) {
          return false;
        }
        
        if (geoFieldName) {
          if ((field.name === geoFieldName + ".STLength()") ||
              (field.name === geoFieldName + ".STArea()")) {
              return false;
            }
        }

        return true;
      })          
    },

    activeLengthUnit() {
      return this.isMBCreated ? this.feature.lengthUnit : this.lengthUnit            
    },

    activeAreaUnit() {
      return this.isMBCreated ? this.feature.areaUnit : this.areaUnit            
    },

    activeLocationVisibleOnMap() {
      return this.isMBCreated ? this.feature.locationVisibleOnMap : this.locationVisibleOnMap
    },

    activeLengthVisibleOnMap() {
      return this.isMBCreated ? this.feature.lengthVisibleOnMap : this.lengthVisibleOnMap    
    },

    activeSideLengthsVisibleOnMap() {
      return this.isMBCreated ? this.feature.sideLengthsVisibleOnMap : this.sideLengthsVisibleOnMap    
    },
    
    activeAreaVisibleOnMap() {
      return this.isMBCreated ? this.feature.areaVisibleOnMap : this.areaVisibleOnMap
    },

    isMBCreated() {
      return this.feature.type === 'annotationEnd' || this.feature.type === 'measurement' || this.feature.type === "userFeature"
    },      
  },  
  watch: {    
    "feature.symbol.color"(newValue) {      
      if (newValue && this.feature.symbol.marker) {
        this.feature.symbol.marker.color = JSON.parse(JSON.stringify(newValue));
      }
    },
    "feature.symbol.outline.color"(newValue) {
      if (newValue && this.feature.type === "annotationEnd") {
        if (this.feature.annotation.line) {
          this.feature.annotation.line.symbol.color =  JSON.parse(JSON.stringify(newValue));        
          this.feature.annotation.line.symbol.marker.color =  JSON.parse(JSON.stringify(newValue));
        }        
      }
    },
    "feature.symbol.outline.width"(newValue) {
      if (this.feature && this.feature.type === "annotationEnd") {
        if (this.feature.annotation.line) {
          this.feature.annotation.line.symbol.width = newValue;            
        }
      }
    }
  },
  methods:
  { 
    getValue(field) {
      let value = this.feature.attributes[field.name];
      if (!value) {
        return "";
      }

      value = this.getFeatureCodedValue(this.feature, field, value);      
      if (field.type === "esriFieldTypeDate") {        
        value = Moment(value).format("DD/MM/YYYY");
      } 
      
      if (typeof(value) === "string") {
        value = value.trim();
      }

      return value;
    },

    autoFocusText() {
      this.whenRefReady("textSymbolProperties", (el) => {                
        el.autoFocusText();
      });        
    },

    attributeChanged(field, value) {
      this.feature.attributes[field.name] = value;      
    },

    getToolTip(attributeName) {
      if ((attributeName === "Length of sides") && (this.getGeometryType(this.feature) === "polyline")) {
        attributeName = "Segment lengths";
      }

      if (this.isMBCreated) {
        return "Show \'"+ attributeName + "\' on map";
      } else {
        return "Show \'"+ attributeName + "\' on map when selected";
      }
    },

    activeCustomFieldVisibleOnMap(field) {
       if (this.isMBCreated) {
        return this.feature.attributesVisibleOnMap.indexOf(field.name) !== -1
      } 
      else
      {
        return field.visibleOnMap;
      }       
    },

    customFieldVisibleOnMapChanged(field) {
      
      if (this.isMBCreated) {
        if (this.activeCustomFieldVisibleOnMap(field)) {
          let index = this.feature.attributesVisibleOnMap.indexOf(field.name);
          this.feature.attributesVisibleOnMap.splice(index, 1);
        } else
        {
          this.feature.attributesVisibleOnMap.push(field.name);
        }        
      } else {
        field.visibleOnMap = !this.activeCustomFieldVisibleOnMap(field);
      }            
    },

    lengthUnitChanged(unit) {
      if (this.isMBCreated) {
        this.feature.lengthUnit = unit;
      } else
      {
        this.$emit("lengthUnitChanged", unit);
      }
    },

    areaUnitChanged(unit) {
      if (this.isMBCreated) {
        this.feature.areaUnit = unit;
      } else
      {
        this.$emit("areaUnitChanged", unit);
      }
    },

    locationVisibleChanged() {
      if (this.isMBCreated) {
        this.feature.locationVisibleOnMap = !this.activeLocationVisibleOnMap
      } else
      {
        this.$emit("locationVisibleOnMapChanged", !this.activeLocationVisibleOnMap);
      }
    },

    lengthVisibleChanged() {   

      if (this.isMBCreated) {
        this.feature.lengthVisibleOnMap = !this.activeLengthVisibleOnMap
      } else
      {
        this.$emit("lengthVisibleOnMapChanged", !this.activeLengthVisibleOnMap);
      }
    },    

    sideLengthsVisibleOnMapChanged() {   

      if (this.isMBCreated) {
        this.feature.sideLengthsVisibleOnMap = !this.activeSideLengthsVisibleOnMap
      } else
      {
        this.$emit("sideLengthsVisibleOnMapChanged", !this.activeSideLengthsVisibleOnMap);
      }
    },    

    areaVisibleChanged() {
      if (this.isMBCreated) {
        this.feature.areaVisibleOnMap = !this.activeAreaVisibleOnMap
      } else
      {
        this.$emit("areaVisibleOnMapChanged", !this.activeAreaVisibleOnMap);
      }
    },

    rowStyle(rowIndex)  {      
      return {
        top: (rowIndex * 28) + "px"
      }
    },

    deleteFeature() {
      this.$emit("delete", this.feature);
    },    

    useAsDefault() {         
      this.$emit("useAsDefault", this.feature);
      this.$root.$emit('bv::hide::popover', 'useAsDefaultButton')
    },

    geometryTypeChanged(e) {         
      this.$emit("geometryTypeChanged", e);         
      this.$emit("activate");      
		},	    
            
    goto() {      
      this.$emit("goto", this.feature);
    },

    gotoAndZoomToFit() {
      this.$emit("gotoAndZoomToFit", this.feature);
    },

    addBookmark() {
      this.$emit("addBookmark", this.feature);
    },      
  },     
}    

</script>

<style lang="scss" scoped>

@import "../variables"; 

.featureProperties, .sketch {
  background-color: $panelContent;
  font-size: $fontSize;    

  .mb-border {
    border-top: 1px solid $panelColourSeperator;    
  } 

  .propertyGrid {
    display: grid;    
    grid-row-gap: 8px;      

    &.best-fit {
      grid-template-columns: min-content auto;
    }

    &.centered {
      grid-template-columns: auto auto;
    }

    .label {      
      display: flex;
      align-items: center;
      justify-self: end;
      white-space: nowrap;
      border-right: 1px solid $controlSeperator;
      border-right: 1px solid $controlSeperator;      
    }    
  }
}

.geometryGrid {
   background-color: $panelColour;
}

.geometryProperties {
  background-color: $panelColourLight
}

.toolbar {
  background-color: $panelColour;
}


.geometrySpan {
  white-space: pre-wrap;
}

button.inactive {
  svg {
    color: $pColourDarkest;
  }
}

</style>