<script>

import { validateClaimsRequest } from "msal/lib-commonjs/AuthenticationParameters";
import Vue from "vue";

var createdFeatureSelection = {
  data() {
    return  {                           
      clearTransform: true,
      creatingAgsFeature:  null,
      identifyFeature: null,
      identifyBorderColour: [109, 217, 252, 1],
      createdAnnotationLine: null,      
      useAsDefaultFeatureEnabled: false,            
      esriGeometryEngine:null,
      esriGraphic:null,      
      svm:null,              

      defaults: {

        systemFeature: {
          isCreating: false,
          type: "system",                
          attributes: {},
          attributesVisibleOnMap: [],
          locationVisibleOnMap: false,
          lengthVisibleOnMap: false,
          lengthUnit: "", 
          sideLengthsVisibleOnMap: false,
          areaUnit: "",           
          areaVisibleOnMap: false,                        
          isSearchResult: false,
          isSelected: false,                          
          isInSelectionRange: false,              
          geometry: null,    
          symbol: null,    
          end: null,
          line: null,
          agsFeature: null,
          layer: null,
          updateGfxLayer: null,
          updateGfxLayerIndex: null,
          transform: false
        },

        annotation: {
          id: "",          
          line: {     
            isCreating: false,       
            id: "",                
            type: "annotationLine",           
            geometry: null,
            symbol: {            
              type: "simple-line",
              style: "solid",            
              color: [244, 143, 177, 1],
              width: 2,
              marker: {
                color: [244, 143, 177, 1],
                placement: "begin",
                style: "arrow",
              }
            },
            annotation: null,            
            agsFeature: null,
            layer: null,
            updateGfxLayer: null,
            updateGfxLayerIndex: null,
            transform: false
          },
          end: {    
            isCreating: false,         
            id: "",          
            type: "annotationEnd",        
            isSelected: false,                
            geometry: null,            
            symbol: {
              type: "simple-fill",
              showFill: true,
              showOutline: true,
              color: [255, 255, 255, 1],  
              style: "solid",
              outline: {
                style: "solid",
                color: [244, 143, 177, 1],
                width: 2
              }
            },   
            annotation: null,
            agsFeature: null,
            layer: null,
            updateGfxLayer: null,
            updateGfxLayerIndex: null,
            transform: false    
          },
          label: {    
            isCreating: false,         
            id: "",          
            type: "annotationLabel",        
            isSelected: false,                
            geometry: null,      
            symbol: {
              type: "text",
              lineHeight: 0.9,
              lineWidth: 512,
              horizontalAlignment: "center",
              verticalAlignment: "middle",
              kerning: false,
              text: "text",
              color: [0, 0, 0, 1], 
              angle: 0,
              haloColor: [255, 255, 255, 1],
              haloSize: 2,
              font: {
                style: "normal",            
                weight: "bold",
                family: "sans-serif",          
                size: 24
              }          
            }, 
            annotation: null,
            agsFeature: null,
            layer: null,
            updateGfxLayer: null,
            updateGfxLayerIndex: null,
            transform: false    
          },
        },

        measurement: {
          point: {           
            isCreating: false,                            
            attributes: {},
            type: "measurement",
            locationVisibleOnMap: true,          
            attributesVisibleOnMap: [],
            lengthVisibleOnMap: false,
            lengthUnit: "meters",
            sideLengthsVisibleOnMap: false,
            areaUnit: "square-meters",          
            areaVisibleOnMap: false,              
            isSelected: false,    
            isSearchResult: false,           
            isInSelectionRange: false,                
            geometry: null,       
            symbol: {
              type: "simple-marker",
              color: [0, 0, 0, 0.3],  
              size: 10,
              style: "circle",
              outline: {                
                color: [0, 200, 0, 1],
                width: 2,
                style: "dash",    
              }
            },          
            end: null,
            line: null,
            agsFeature: null,
            layer: null,
            updateGfxLayer: null,
            updateGfxLayerIndex: null,
            transform: false
          },
          polyline: {
            isCreating: false,       
            id: "",
            type: "measurement",
            attributes: {},
            attributesVisibleOnMap: [],
            locationVisibleOnMap: false,
            lengthVisibleOnMap: false,
            lengthUnit: "meters",        
            sideLengthsVisibleOnMap: true,
            areaUnit: "", 
            areaVisibleOnMap: false,              
            isSelected: false,    
            isSearchResult: false,          
            isInSelectionRange: false,                
            geometry: null,      
            symbol: {
              type: "simple-line",
              style: "dash",            
              color: [0, 200, 0, 1],
              width: 2,    
              marker: {
                color: [0, 200, 0, 1],
                placement: "begin-end",
                style: "cross",
              }
            },          
            end: null,
            line: null,
            agsFeature: null,
            layer: null,
            updateGfxLayer: null,
            updateGfxLayerIndex: null,
            transform: false         
          },

          rectangle: {    
            isCreating: false,             
            id: "",          
            type: "measurement",
            attributes: {},
            attributesVisibleOnMap: [],
            locationVisibleOnMap: false,
            lengthVisibleOnMap: true,
            lengthUnit: "meters",            
            areaUnit: "square-meters",     
            sideLengthsVisibleOnMap: false,      
            areaVisibleOnMap: true,                    
            isSelected: false,    
            isSearchResult: false,         
            isInSelectionRange: false,                
            geometry: null,      
            symbol: {
              type: "simple-fill",
              showFill: true,
              showOutline: true,
              color: [0, 0, 0, 0.3],  
              style: "solid",
              outline: {
                style: "dash",             
                color: [0, 200, 0, 1],
                width: 2
              }
            },
            end: null,
            line: null,
            agsFeature: null,
            layer: null,
            updateGfxLayer: null,
            updateGfxLayerIndex: null,
            transform: false         
          },
          circle: {      
            isCreating: false,         
            id: "",        
            type: "measurement",
            attributes: {},
            attributesVisibleOnMap: [],
            locationVisibleOnMap: false,
            lengthVisibleOnMap: true,
            lengthUnit: "meters",
            sideLengthsVisibleOnMap: false,      
            areaUnit: "square-meters",      
            areaVisibleOnMap: true,              
            isSelected: false,    
            isSearchResult: false,
            isInSelectionRange: false,                
            geometry: null,          
            symbol: {
              type: "simple-fill",
              showFill: true,
              showOutline: true,
              color: [0, 0, 0, 0.3],  
              style: "solid",
              outline: {
                style: "dash",              
                color: [0, 200, 0, 1],
                width: 2
              }
            },
            end: null,
            line: null,
            agsFeature: null,
            layer: null,
            updateGfxLayer: null,
            updateGfxLayerIndex: null,
            transform: false                         
          },
          polygon: {      
            isCreating: false,           
            id: "",         
            attributes: {},
            type: "measurement",
            attributesVisibleOnMap: [],
            locationVisibleOnMap: false,
            lengthVisibleOnMap: true,
            lengthUnit: "meters",
            sideLengthsVisibleOnMap: false,      
            areaUnit: "square-meters",          
            areaVisibleOnMap: true,              
            isSelected: false,    
            isSearchResult: false, 
            isInSelectionRange: false,                
            geometry: null,          
             symbol: {
              type: "simple-fill",
              showFill: true,
              showOutline: true,
              color: [0, 0, 0, 0.3],  
              style: "solid",
              outline: {
                style: "dash",             
                color: [0, 200, 0, 1],
                width: 2
              }
            },
            end: null,
            line: null,
            agsFeature: null,
            layer: null,
            updateGfxLayer: null,
            updateGfxLayerIndex: null,
            transform: false           
          },        
        },

        userFeature: {
          point: {                                
            type: "userFeature",
            attributes: {         
              MBID: "",
              MB_NAME: ""              
            },
            attributesVisibleOnMap: ["MB_NAME"],                                       
            locationVisibleOnMap: false,                      
            lengthVisibleOnMap: false,
            sideLengthsVisibleOnMap: false,     
            areaVisibleOnMap: false,              
            lengthUnit: "meters",            
            areaUnit: "square-meters",                       
            isSelected: false,    
            isSearchResult: false,           
            isInSelectionRange: false,                
            geometry: null,       
            symbol: {
              type: "simple-marker",
              color: [0, 0, 0, 1],  
              style: "circle",
              size: 10,
              outline: {
                style: "solid",            
                color: [0, 0, 200, 1],
                width: 2
              }
            },
            isCreating: false,       
            end: null,
            line: null,
            agsFeature: null,
            layer: null,
            updateGfxLayer: null,
            updateGfxLayerIndex: null,
            transform: false       
          },

          polyline: {    
            isCreating: false,               
            type: "userFeature",
            attributes: {         
              MBID: "",
              MB_NAME: ""              
            },
            locationVisibleOnMap: false,          
            attributesVisibleOnMap: ["MB_NAME"],               
            lengthVisibleOnMap: false,
            lengthUnit: "meters",
            areaUnit: "square-meters",          
            areaVisibleOnMap: false,             
            sideLengthsVisibleOnMap: false,       
            isSelected: false,    
            isSearchResult: false,           
            isInSelectionRange: false,                
            geometry: null,       
            symbol: {
              type: "simple-line",
              style: "solid",            
              color: [0, 0, 200, 1],
              width: 4    
            },
            end: null,
            line: null,
            agsFeature: null,
            layer: null,
            updateGfxLayer: null,
            updateGfxLayerIndex: null,
            transform: false      
          },

          rectangle: {     
            isCreating: false,       
            attributes: {         
              MBID: "",
              MB_NAME: ""              
            },
            type: "userFeature",
            locationVisibleOnMap: false,          
            attributesVisibleOnMap: ["MB_NAME"],               
            lengthVisibleOnMap: false,
            lengthUnit: "meters",
            sideLengthsVisibleOnMap: false,      
            areaUnit: "square-meters",          
            areaVisibleOnMap: false,              
            isSelected: false,    
            isSearchResult: false,          
            isInSelectionRange: false,                
            geometry: null,        
            symbol: {
              type: "simple-fill",
              showFill: true,
              showOutline: true,
              color: [0, 0, 0, 0.4],  
              style: "solid",
              outline: {
                style: "solid",            
                color: [0, 0, 200, 1],
                width: 4
              }
            },            
            end: null,
            line: null,
            agsFeature: null,
            layer: null,
            updateGfxLayer: null,
            updateGfxLayerIndex: null,
            transform: false            
          },
          circle: {       
            isCreating: false,        
            attributes: {         
              MBID: "",
              MB_NAME: ""              
            },
            type: "userFeature",
            locationVisibleOnMap: false,          
            attributesVisibleOnMap: ["MB_NAME"],               
            lengthVisibleOnMap: false,
            lengthUnit: "meters",
            sideLengthsVisibleOnMap: false,      
            areaUnit: "square-meters",          
            areaVisibleOnMap: false,              
            isSelected: false,    
            isSearchResult: false,         
            isInSelectionRange: false,                
            geometry: null,               
             symbol: {
              type: "simple-fill",
              showFill: true,
              showOutline: true,
              color: [0, 0, 0, 0.4],  
              style: "solid",
              outline: {
                style: "solid",            
                color: [0, 0, 200, 1],
                width: 4
              }
            },              
            end: null,
            line: null,
            agsFeature: null,
            layer: null,
            updateGfxLayer: null,
            updateGfxLayerIndex: null,
            transform: false            
          },
          polygon: {  
            isCreating: false,            
            attributes: {         
              MBID: "",
              MB_NAME: ""              
            },
            type: "userFeature",            
            locationVisibleOnMap: false,          
            attributesVisibleOnMap: ["MB_NAME"],               
            lengthVisibleOnMap: false,
            lengthUnit: "meters",
            sideLengthsVisibleOnMap: false,      
            areaUnit: "square-meters",          
            areaVisibleOnMap: false,              
            isSelected: false,    
            isSearchResult: false,         
            isInSelectionRange: false,                
            geometry: null,                          
             symbol: {
              type: "simple-fill",
              showFill: true,
              showOutline: true,
              color: [0, 0, 0, 0.4],  
              style: "solid",
              outline: {
                style: "solid",            
                color: [0, 0, 200, 1],
                width: 4
              }
            },             
            end: null,
            line: null,
            agsFeature: null,
            layer: null,
            updateGfxLayer: null,
            updateGfxLayerIndex: null,
            transform: false           
          },        
        }
      }    
    }
  },        
  
  methods: {           
    identifyFillColour() {
      let colour = this.selectionOutlineColour.slice();
      colour[3] = 0.2;
      return colour;
    },

    geometryContainsOrIntersects(sourceGeometry, targetGeometry) {
      return this.agsGeometryEngine.contains(sourceGeometry, targetGeometry) ||
             this.agsGeometryEngine.intersects(sourceGeometry, targetGeometry)
    },
    
    getIdentifyBufferSymbol() {
      return {
        type: "simple-fill",
        color: this.identifyFillColour(),            
        style: "cross",
        outline: {
          color: this.identifyBorderColour,
          width: 2,
          style: "solid"
        }          
      }
    },

    getIdentifyBufferGraphic(geometry) {

      if (this.identifyBufferSize > 0) {

        let bufferGeom = this.agsGeometryEngine.buffer(
          geometry,
          this.identifyBufferSize,
          this.identifyBufferUnit
        );        
      
        let bufferGraphic = new this.agsGraphic({
          geometry: bufferGeom,            
          symbol: this.getIdentifyBufferSymbol()
        })

        return bufferGraphic;
      }      
    },

    geometryTypeChanged(geometryType) {
      if (this.activePointerTool === "createFeature" || 
          this.activePointerTool === "measure" || 
          this.activePointerTool === "annotate" || 
          this.activePointerTool === "identify" || 
          this.activePointerTool === "lineAnnotate") {
        this.create();   
      } else
      {        
        this.createSvm.cancel();
      }
    },

    update(transformables) {
      if (transformables.length > 0) {          

        transformables.forEach(feature => {        
          let agsFeature = feature.agsFeature;              
          // Remember layer before moving to update layer.          
          feature.updateGfxLayer = agsFeature.layer;
          feature.updateGfxLayerIndex = agsFeature.layer.graphics.indexOf(agsFeature);                      

          agsFeature.layer.graphics.remove(agsFeature);
          this.svmUpdateLayer.graphics.add(agsFeature);        

          if (feature.type === "annotationEnd") {
            let labelFeature = feature.annotation.label;
            agsFeature = labelFeature.agsFeature;              
            labelFeature.updateGfxLayer = agsFeature.layer;
            labelFeature.updateGfxLayerIndex = agsFeature.layer.graphics.indexOf(agsFeature);                      

            agsFeature.layer.graphics.remove(agsFeature);
            this.updateAnnotationLabelsGfxLayer.graphics.add(agsFeature);        
          }
        })

        let tool;

        if (transformables.length === 1) {
          let feature = transformables[0];                        
          if (feature.type === "annotationEnd") {
            tool = "transform"
          } else {
            tool = (feature.geometry.geometryType === "point") ? "move" : "reshape"
          }          
        } else {
          tool = "move";
        }

        let options = 
        {
          tool: tool,
          toggleToolOnClick: false,
          enableScaling: true,
          preserveAspectRatio: false,
          enableRotation: false,
          enableZ: false,
          multipleSelectionEnabled: true,        
          highlightOptions: {
            enabled: false
          }        
        }            
                                                
        /* let updateIndex = this.mapView.map.layers.indexOf(feature.agsFeature);
        this.mapView.map.layers.reorder(this.svmUpdateLayer, updateIndex + 1);                        */

        //console.log(this.mapView.map.layers.items.map(layer => layer.id));
        
        let agsFeatures = transformables.map(feature => feature.agsFeature);                              
        this.updateSvm.update(agsFeatures, options);          
      }
    },

    showUserFeatureAndMeasurementLabels() {      
      if (this.userFeaturesLabelsGfxLayer && this.updateLabelsGfxLayer && this.measurementsLabelsGfxLayer && this.svmCreateLayer) {
      
        this.userFeaturesLabelsGfxLayer.removeAll();
        this.updateLabelsGfxLayer.removeAll();    
        this.measurementsLabelsGfxLayer.removeAll();

        let features = [...this.userFeatures, ...this.measurements];    

        features.forEach(feature => {
          
          let labelGraphics = this.getLabelGraphics(feature, feature.isCreating);
          labelGraphics.forEach(graphic => {                      
            if (feature.isCreating || feature.transform) {                            
              this.updateLabelsGfxLayer.add(graphic);             
            } else {              
              if (feature.type === "userFeature") {
                this.userFeaturesLabelsGfxLayer.add(graphic);
              } else 
              {

                this.measurementsLabelsGfxLayer.add(graphic);         
              }
            }           
          })                      
        })
      } 
    },  

    getIdentifySymbol(geometryType) {
      if (geometryType === "polygon") {
        return {
          type: "simple-fill",                    
          color: this.identifyFillColour(),
          style: "cross",
          outline: {
            color: this.identifyBorderColour,
            width: 2,
            style: "dash"
          }
        }         
      }

      if (geometryType === "polyline") {
        return  {
          type: "simple-line",
          color: this.identifyBorderColour,
          width: 2,
          style: "dash"
        }        
      }

      let fullColour = this.identifyBorderColour.slice();
      fullColour[3] = 1;

      if (geometryType === "point") {
        return {
          type: "simple-marker",
          color: fullColour,
          size: 8,
          style: "circle"          
        }          
      }
    },

    create() {      

      let geometryType;
      let polygonSymbol;
      let lineSymbol;
      let pointSymbol;

      if (this.activePointerTool === "identify") {        

        geometryType = this.identifyGeometryType;
                                       
        polygonSymbol = this.getIdentifySymbol("polygon");        
        lineSymbol  = this.getIdentifySymbol("polyline");
        pointSymbol = this.getIdentifySymbol("point");                   
      }             

      if (this.activePointerTool === "zoomTo") {        

        geometryType = "rectangle"
      
        polygonSymbol = {
          style: "none",
          type: "simple-fill",                              
          outline: {
            color: this.identifyBorderColour,
            width: 2,
            style: "dash"
          }
        }         
      }                         

      if (this.activePointerTool === "createFeature") {

        geometryType = this.createFeatureGeometryType;
        
        let polygonDefaults = this.defaults.userFeature.polygon;
        polygonSymbol = polygonDefaults.symbol;
        polygonSymbol = JSON.parse(JSON.stringify(polygonSymbol));                         
        
        let lineDefaults = this.defaults.userFeature.polyline;
        lineSymbol = lineDefaults.symbol;
        lineSymbol = JSON.parse(JSON.stringify(lineSymbol));      
        lineSymbol = this.vueSymbolToAgsSymbol(lineSymbol);        

        let pointDefaults = this.defaults.userFeature.point;
        pointSymbol = pointDefaults.symbol;
        pointSymbol = JSON.parse(JSON.stringify(pointSymbol));      
        pointSymbol = this.vueSymbolToAgsSymbol(pointSymbol);        
      }         
      
      if (this.activePointerTool === "measure") {

        geometryType = this.measureGeometryType
                
        let polygonDefaults = this.defaults.measurement.polygon;
        polygonSymbol = polygonDefaults.symbol;
        polygonSymbol = JSON.parse(JSON.stringify(polygonSymbol));     
        polygonSymbol = this.vueSymbolToAgsSymbol(polygonSymbol);   
                     
        let lineDefaults = this.defaults.measurement.polyline;
        lineSymbol = lineDefaults.symbol;
        lineSymbol = JSON.parse(JSON.stringify(lineSymbol));      
        lineSymbol = this.vueSymbolToAgsSymbol(lineSymbol);        
        
        let pointDefaults = this.defaults.measurement.point;
        pointSymbol = pointDefaults.symbol;
        pointSymbol = JSON.parse(JSON.stringify(pointSymbol));      
        pointSymbol = this.vueSymbolToAgsSymbol(pointSymbol);        
      }

      if (this.activePointerTool === "annotate") {

        geometryType = "rectangle"
        let defaults = this.defaults.annotation.end;
        polygonSymbol = defaults.symbol;
        polygonSymbol = JSON.parse(JSON.stringify(polygonSymbol));
        polygonSymbol = this.vueSymbolToAgsSymbol(polygonSymbol);              
      }

      if (this.activePointerTool === "lineAnnotate") {

        geometryType = "polyline"

        let lineDefaults = this.defaults.annotation.line;
        lineSymbol = lineDefaults.symbol;
        lineSymbol = JSON.parse(JSON.stringify(lineSymbol));        
        lineSymbol = this.vueSymbolToAgsSymbol(lineSymbol);              
      }
      
      if (polygonSymbol) {
        this.createSvm.polygonSymbol = polygonSymbol;
      }

      if (lineSymbol) {
        this.createSvm.polylineSymbol = lineSymbol;
      }

      if (pointSymbol) {
        this.createSvm.pointSymbol = pointSymbol;        
      }
      
      this.createSvm.create(geometryType);               
    },   

    featurePreCreate(agsFeature) {      

      if (this.activePointerTool === "identify") {
          
        let inSelectionRange = this.featuresInSelectionRange.slice();      
        inSelectionRange.forEach(feature => {
          feature.isInSelectionRange = false;        
        });

        this.identifyGfxLayer.removeAll();

        let feature = Vue.observable({
          type: "identify",      
          agsFeature: agsFeature,
          bufferGraphic: null
        });        
                                                      
        this.agsFeatureToVueFeature.set(agsFeature, feature);

        let bufferGraphic = this.getIdentifyBufferGraphic(agsFeature.geometry);
        if (bufferGraphic) {
          feature.bufferGraphic = bufferGraphic;
          this.identifyGfxLayer.add(bufferGraphic);
        }

        this.identifyFeature = feature;
        feature.isCreating = true;
        return feature;
      }

      if (this.activePointerTool === "createFeature") {
                        
        let defaults = this.defaults.userFeature[this.createFeatureGeometryType];
        let feature = JSON.parse(JSON.stringify(defaults));   

        feature.attributes.MBID = this.uuid();        
        feature.geometry = agsFeature.geometry,      
        feature.geometry.typeEx = this.createFeatureGeometryType,     
        feature.layer = this.userFeaturesLayer;        
        this.userFeaturesLayer.features.push(feature);                        

        this.copyAgsFeatureToVueFeature(agsFeature, feature);  

        feature.agsFeature = agsFeature;
        this.agsFeatureToVueFeature.set(agsFeature, feature);

        feature.isCreating = true;
        return feature;

      } else if (this.activePointerTool === "measure") {

        let defaults = this.defaults.measurement[this.measureGeometryType];
        let feature = JSON.parse(JSON.stringify(defaults));   

        feature.id = this.uuid();         
        feature.geometry = agsFeature.geometry,      
        feature.geometry.typeEx = this.measureGeometryType,                     
        this.measurements.push(feature);

        this.copyAgsFeatureToVueFeature(agsFeature, feature);          

        feature.agsFeature = agsFeature;
        this.agsFeatureToVueFeature.set(agsFeature, feature);
        
        feature.isCreating = true;
        return feature;
      } else if (this.activePointerTool === "lineAnnotate") {

        let defaults = this.defaults.annotation.line;
        let feature = JSON.parse(JSON.stringify(defaults));  
        feature.geometry = agsFeature.geometry,      
        feature.geometry.typeEx = "polyline",     
         
        feature.id = this.uuid();                                    

        feature.agsFeature = agsFeature;
        this.agsFeatureToVueFeature.set(agsFeature, feature);

        feature.isCreating = true;
        return feature;
      } else if (this.activePointerTool === "annotate") {      

        let annotation = this.createAnnotation(null, agsFeature);                               
        annotation.end.isCreating = true;
        return annotation.end;
      }                               
    }, 

    createAnnotationEndAgsFeature(mapPoint) {
      let screenPoint = this.mapView.toScreen(mapPoint);         

      let endAgsFeature;

      let width = window.innerWidth * 0.10;
      let height = window.innerHeight * 0.10;        
      let halfWidth = width / 2;
      let halfHeight = height / 2;
      let half = Math.min(halfWidth, halfHeight);

      let topLeftX = [screenPoint.x - half]
      let topLeftY = [screenPoint.y - half];

      let topRightX = [screenPoint.x + half]
      let topRightY = [screenPoint.y - half];

      let bottomRightX = [screenPoint.x + half]
      let bottomRightY = [screenPoint.y + half];

      let bottomLeftX = [screenPoint.x - half]
      let bottomLeftY = [screenPoint.y + half];

      let mapTopLeft = this.mapView.toMap({x: topLeftX, y: topLeftY});
      let mapTopRight = this.mapView.toMap({x: topRightX, y: topRightY});
      let mapBottomRight = this.mapView.toMap({x: bottomRightX, y: bottomRightY});
      let mapBottomLeft = this.mapView.toMap({x: bottomLeftX, y: bottomLeftY});

      const rings = [
        [  
          [mapTopLeft.x, mapTopLeft.y],
          [mapTopRight.x, mapTopRight.y],
          [mapBottomRight.x, mapBottomRight.y],
          [mapBottomLeft.x, mapBottomLeft.y],
          [mapTopLeft.x, mapTopLeft.y]  // same as first vertex
        ]
      ];
                        
      let geometry = new this.agsPolygon({          
        rings: rings,
        spatialReference: mapPoint.spatialReference
      });

      endAgsFeature = new this.agsGraphic({                             
        geometry: geometry,          
      })        

      return endAgsFeature;
    },

    createAnnotationEnd(point, endAgsFeature) {            

      if (!endAgsFeature) {
        endAgsFeature = this.createAnnotationEndAgsFeature(point);
      }
      
      let defaults = this.defaults.annotation.end;              
      let endFeature = JSON.parse(JSON.stringify(defaults));                           
          
      endFeature.id = this.uuid(),
      endFeature.geometry = endAgsFeature.geometry        
      endFeature.geometry.typeEx = "rectangle"             

      this.copyAgsFeatureToVueFeature(endAgsFeature, endFeature);        
      
      endFeature.agsFeature = endAgsFeature;      
      this.agsFeatureToVueFeature.set(endAgsFeature, endFeature);

      return endFeature;
    },

    createAnnotation(annotationLine, endAgsFeature) {    

      if (annotationLine) {
        annotationLine.geometry = annotationLine.agsFeature.geometry;
        annotationLine.geometry.typeEx = "polyline"
      }            

      let end;

      if (!endAgsFeature) {

        let endOfLineGeometry = annotationLine.agsFeature.geometry;                      
        let lastPath = endOfLineGeometry.paths[endOfLineGeometry.paths.length -1];
    
        let lastPointCoords = lastPath[lastPath.length -1];              
        let mapPoint = {x: lastPointCoords[0], 
                        y: lastPointCoords[1],
                        spatialReference: endOfLineGeometry.spatialReference};                                            

        end = this.createAnnotationEnd(mapPoint, null);        
      } 
      else
      {
        end = this.createAnnotationEnd(null, endAgsFeature);        
      }               

      let defaults = this.defaults.annotation.label;              
      let labelFeature = JSON.parse(JSON.stringify(defaults));          
      
      let agsLabelFeature = new this.agsGraphic({                  
        geometry: end.agsFeature.geometry.extent.center
      });               
          
      labelFeature.id = this.uuid(),
      labelFeature.geometry = agsLabelFeature.geometry,
      labelFeature.geometry.typeEx = "point"             

      this.copyAgsFeatureToVueFeature(agsLabelFeature, labelFeature);          

      labelFeature.agsFeature = agsLabelFeature;      
      this.agsFeatureToVueFeature.set(agsLabelFeature, labelFeature); 

      let annotation = {
        type: "annotation",
        id: this.uuid(),          
        line: annotationLine,
        end: end,
        label: labelFeature,
      }
      
      end.annotation = annotation;
      labelFeature.annotation = annotation;

      if (annotationLine) {
        annotationLine.annotation = annotation;
      }
      
      this.annotations.push(annotation)
      return annotation;
    },         

    async addDeletion(features) {      

      if (this.deletionHistory.length > 20) {
        this.deletionHistory.shift();
      }
            
      this.deletionHistory.push(features);      
      await this.queueSyncProject();
    },

    onFeaturesUndo(e) {                  
      
      e.graphics.forEach(graphic => {          

        let feature =  this.agsFeatureToVueFeature.get(graphic);

        if (feature.type =="annotationEnd") { 

          let annotation = feature.annotation;
          annotation.label.geometry = graphic.geometry.extent.center
          annotation.label.agsFeature.geometry = graphic.geometry.extent.center
        }            

        this.copyAgsFeatureToVueFeature(graphic, feature);
      })                          
    },


    onFeaturesDeleted(e) {                  
      // For some reason, when deleting a point, this handler gets fired before keyup does.
      // Dont come in here if this is the case, and process delete from the key up handler.

      if (this.processSVMDelete) {          
        
        let graphics = e.graphics.slice();      

        graphics.forEach(graphic => {    
          let vueFeature = this.agsFeatureToVueFeature.get(graphic);
          if (vueFeature) {          
            this.doDeleteFeature(vueFeature);                  
          }
        })            

        this.processSVMDelete = false;
      }
    },
    
    async deleteFeatures(features) {                 

      let deletions = features.map(feature => {
        if (feature.type === "annotationEnd") {
          return this.annotations.find(annotation => annotation.end === feature);
        }
        return feature;
      });

      this.addDeletion(deletions)            
      
      let transforming = features.filter(feature => feature.transform);
      let notTransforming = features.filter(feature => !feature.transform);

      if (transforming.length > 0) {
        this.processSVMDelete = true;     
        this.updateSvm.delete();
      }      

      notTransforming.forEach(feature => {
        this.deleteFeature(feature);
      })            
    },
    
    deleteFeature(feature) {      
      if (feature.isCreating) {        
        this.createSvm.cancel();
      } 
      else     
      {
        this.doDeleteFeature(feature);        
      }
    },

    doDeleteFeature(feature) {           

      if ((feature.type === "userFeature") ||
          (feature.type === "measurement") ||
          (feature.type === "annotationEnd")) {          
                               
        feature.isSelected = false;                                             

        if (feature.type === "userFeature") {
          this.userFeaturesGfxLayer.graphics.remove(feature.agsFeature);          
          let index = feature.layer.features.indexOf(feature);        
          feature.layer.features.splice(index, 1);    

        } else if (feature.type === "annotationEnd") {

          let annotation = feature.annotation;

          let index;

          // remove line.
          if (annotation.line) {            
            this.annotationLinesGfxLayer.graphics.remove(annotation.line.agsFeature);          
          }  
                                    
          if (!feature.transform) {
            this.annotationEndsGfxLayer.graphics.remove(feature.agsFeature);                
            this.annotationLabelsGfxLayer.graphics.remove(annotation.label.agsFeature);
          } else
          {
            this.updateAnnotationLabelsGfxLayer.graphics.remove(annotation.label.agsFeature);
          }

          let annotationIndex = this.annotations.indexOf(annotation);
          this.annotations.splice(annotationIndex, 1);        
                        
        } else if (feature.type === "measurement") {

          let index = this.measurements.indexOf(feature);        
          this.measurements.splice(index, 1);
          this.measurementsGfxLayer.graphics.remove(feature.agsFeature);                          
        }

        this.agsFeatureToVueFeature.delete(feature.agsFeature);        
        
        let index = this.clickedFeatures.indexOf(feature);
        if (index !== -1) {
          this.clickedFeatures.splice(index, 1);
        }        

        this.deleteNavItemFeature(feature);
      }
    }, 

    addPrivateLayersGroup() {

      let layer = Vue.observable({                            
        id: "privateLayers",              
        includeInLayerManager: true,
        sublayers: [],
        allSublayers: [],
        title: "My private layers",
        url: null,
        expandedInAllTree: true,
        expandedInFavsTree: true, 
        expandedInVisibleTree: true,                                        
        visible: true,      
      });
    
      this.defineLayerProperties(layer);            
      this.layers.push(layer);
    },
   
    addUserFeaturesLayer() {

      let userFeaturesLayer = Vue.observable(
      {
        id: "createdFeatures",        
        title: "My Features",         
        objectIdPropertyName: "MBID",
        namePropertyName: "MB_NAME",
        features: [],
        agsLayer: null,
        fields: [
        { 
          name: "MB_NAME",
          alias: "Name",
          type: "esriFieldTypeString",         
          identifyResultsFilterText: "",           
          canEdit: true,
          domain: null
        },
        {
          name: "MBID",
          alias: "ID",     
          identifyResultsFilterText: "",                
          type: "esriFieldTypeString",                    
          domain: null
        },                
        ],               
        parent: null,
        rootLayer:null,
        identifyResultsSortedField: null,
        identifyResultsSortedAscending: true,
      })
      
      userFeaturesLayer.identifyResultsSortedField = userFeaturesLayer.fields.find(field => field.name === "MB_NAME");
      this.userFeaturesLayer = userFeaturesLayer;

      this.defineLayerProperties(this.userFeaturesLayer);  

      let layer = Vue.observable({                            
        id: "createdFeaturesRootLayer",              
        title: "createdFeaturesRootLayer",              
        includeInLayerManager: false,
        sublayers: [this.userFeaturesLayer],
        allSublayers: [this.userFeaturesLayer],
        agsLayer: null,
        symbol: null
      });

      this.userFeaturesLayer.parent = layer;
      this.userFeaturesLayer.rootLayer = layer;
      this.defineLayerProperties(layer);            
      this.layers.push(layer);
    },    

    clearSelectedFeatures() {    

      this.clickedFeatures = [];
      
      let features = this.selectedFeatures.slice();                
      features.forEach(selectedFeature => {        
        selectedFeature.isSelected = false;                
        selectedFeature.transform = false;
      })      

      this.selectedLayers.slice()
      .forEach(x => {
        x.isSelected = false;
      })
    },
    
    async onFeatureCreated(e) {
      
      let vueFeature;            
      
      switch(e.state)
      {        
        case "start":                                             

          if (this.activePointerTool !== "zoomTo") {
          
            let topMostLayer = this.mapView.map.layers.items[this.mapView.map.layers.length - 1];
            let creationIndex = this.mapView.map.layers.indexOf(this.svmCreateLayer);
            this.mapView.map.layers.reorder(topMostLayer, creationIndex + 1);          

            if ((this.activePointerTool === "measure" || this.activePointerTool === "createFeature") ||
                ((this.activePointerTool === "lineAnnotate") && 
                (this.selectedFeatures.length > 0) && 
                (this.selectedFeatures[0].type === "annotationEnd"))) {
              this.clearSelectedFeatures();                        
            }        
          }

          break;

        case "active":                        

          if (this.activePointerTool !== "zoomTo" && this.activePointerTool !== "defaultTool") {
                 
            if (this.creatingAgsFeature) {            
              vueFeature = this.agsFeatureToVueFeature.get(this.creatingAgsFeature);
            } else {
              vueFeature = this.featurePreCreate(e.graphic);                   
              this.creatingAgsFeature = e.graphic;          
            }

            if (vueFeature.type !== "annotationLine" && vueFeature.type !== "identify") {
              vueFeature.isSelected = true;              
              
            }

            if (vueFeature.type === "identify") {
              this.identifyGfxLayer.removeAll()
              let bufferGraphic = this.getIdentifyBufferGraphic(e.graphic.geometry);
              if (bufferGraphic) {
                vueFeature.bufferGraphic = bufferGraphic;
                this.identifyGfxLayer.add(bufferGraphic);              
              }
            }

            if (vueFeature.type === "annotationEnd") {

              let annotation = vueFeature.annotation;
              annotation.label.geometry = e.graphic.geometry.extent.center
              annotation.label.agsFeature.geometry = e.graphic.geometry.extent.center
              
              let line = annotation.line;

              if (line) {
                this.copyAgsFeatureToVueFeature(line.agsFeature, line);                   
              }
            }
                      
            this.copyAgsFeatureToVueFeature(e.graphic, vueFeature);              
          }
                    
          break;          
        case "complete":                                               

          if (this.activePointerTool === "zoomTo") {             
             this.svmCreateLayer.graphics.remove(e.graphic);                                       
             await this.goto(e.graphic);                          
             
          } else {
        
            if (this.activePointerTool !== "identify") {
              this.clearSelectedFeatures();                        
            }                    

            if(!this.creatingAgsFeature) {            
              vueFeature = this.featurePreCreate(e.graphic);                   
            } else {
              vueFeature = this.agsFeatureToVueFeature.get(this.creatingAgsFeature);
              vueFeature.agsFeature = e.graphic;
              this.agsFeatureToVueFeature.set(e.graphic, vueFeature);                        
              this.creatingAgsFeature = null;
            }                                            

            this.copyAgsFeatureToVueFeature(e.graphic, vueFeature);

            if (vueFeature.type === "identify") {
              this.svmCreateLayer.graphics.remove(e.graphic);
              this.identifyGfxLayer.graphics.add(e.graphic);         
            }

            if (vueFeature.type === "userFeature") {
              this.svmCreateLayer.graphics.remove(e.graphic);
              this.userFeaturesGfxLayer.graphics.add(e.graphic);         
            }

            if (vueFeature.type === "measurement") {
              this.svmCreateLayer.graphics.remove(e.graphic);
              this.measurementsGfxLayer.graphics.add(e.graphic);         
            }

            if (vueFeature.type === "annotationEnd") {
              this.svmCreateLayer.graphics.remove(e.graphic);          
              this.annotationEndsGfxLayer.graphics.add(e.graphic); 
              this.annotationLabelsGfxLayer.graphics.add(vueFeature.annotation.label.agsFeature);             
            }

            if (vueFeature.type === "annotationLine") {
              this.svmCreateLayer.graphics.remove(e.graphic);
              this.annotationLinesGfxLayer.graphics.add(e.graphic);         
            }

            vueFeature.isCreating = false;                                         

            if (vueFeature.type === "annotationLine") {            
              let annotation = this.createAnnotation(vueFeature, null);                   
              this.annotationEndsGfxLayer.graphics.add(annotation.end.agsFeature);      
              this.annotationLabelsGfxLayer.graphics.add(annotation.label.agsFeature);            

              annotation.end.isSelected = true;                                      
              this.addNavItem([annotation.end]);
              annotation.end.transform = true;
            
              this.whenRefReady("featureProperties", (el) => {                
                el.autoFocusText();
              });                 

              this.$emit("openMenuItem", "properties");            

            } else if (vueFeature.type === "identify") {
              this.identifySelectionGeometryChanged();
              this.$emit("openMenuItem", "results");               
            } else {                                        

              this.addNavItem([vueFeature]);            

              if (vueFeature.type === "annotationEnd") {                              

                vueFeature.isSelected = true;                                        
                vueFeature.transform = true;            

                this.whenRefReady("featureProperties", (el) => {                
                  el.autoFocusText();
                });                

                this.$emit("openMenuItem", "properties");       
              }                   
            }     
            
            this.processClick = false;
          }
          this.activePointerTool = "defaultTool";    
                    
          break;                     
        case "cancel":                       

          if (this.activePointerTool === "zoomTo") {          
            this.svmCreateLayer.graphics.remove(e.graphic);   
          } else {
          
            vueFeature = this.agsFeatureToVueFeature.get(this.creatingAgsFeature);    // Set to "null" in DeleteFeature.
            this.creatingAgsFeature = null;
            if (vueFeature) {
              vueFeature.isCreating = false;
              this.doDeleteFeature(vueFeature);     
              this.addDeletion([vueFeature]);                         
            }                

            if (this.activePointerTool === "createFeature" || this.activePointerTool === "measure" || this.activePointerTool === "annotate" || this.activePointerTool === "lineAnnotate") {
              // Keep creating.
              this.create();   
            }        
          }        

          break;
      }                
    },

    onFeaturesUpdated(e) {      
      
      if (e.state === "start")
      {                                       
      }
      else if (e.state === "active")
      {                                         
        this.processClick = false;
        
        if ((!e.toolEventInfo ||  e.toolEventInfo.type !== "scale-start" && e.toolEventInfo.type !== "scale" && e.toolEventInfo.type !== "scale-stop") &&
            (this.transformableFeatures.some(feature => 
                                            feature.type === "annotationEnd" &&
                                            feature.annotation.line))) {          

            let features = this.transformableFeatures.slice();                                                                                        
            features.forEach(feature => { 
              feature.isSelected = false
              feature.transform = false
            })    
            
            return;
        } 

        e.graphics.forEach(graphic => {          

          let feature =  this.agsFeatureToVueFeature.get(graphic);

          if (feature.type =="annotationEnd") { 

            let annotation = feature.annotation;
            annotation.label.geometry = graphic.geometry.extent.center
            annotation.label.agsFeature.geometry = graphic.geometry.extent.center
          }            
          
          this.copyAgsFeatureToVueFeature(graphic, feature);
        })                            
      }       

      if (e.state === "complete") {                  
        e.graphics.slice().forEach(agsFeature => { 

          let vueFeature = this.agsFeatureToVueFeature.get(agsFeature);

          if (vueFeature) {  
                      
            this.copyAgsFeatureToVueFeature(agsFeature, vueFeature);        

           // Move graphics from update layers back to source layers.      
            this.svmUpdateLayer.graphics.remove(agsFeature);
            vueFeature.updateGfxLayer.graphics.add(agsFeature, vueFeature.updateGfxLayerIndex);                                

            if (vueFeature.type === "annotationEnd") {
              let labelFeature = vueFeature.annotation.label;
              agsFeature = labelFeature.agsFeature;              

              this.updateAnnotationLabelsGfxLayer.graphics.remove(agsFeature);          
              labelFeature.updateGfxLayer.graphics.add(agsFeature, labelFeature.updateGfxLayerIndex);                                  
            }         
                                                                       
            if (this.clearTransform) {              
              vueFeature.transform = false;
            }
          }
        });      
      }
    },

    vueSymbolToAgsSymbol(vueSymbol) {

      let symbol = null;

      if (vueSymbol !== null) {
      
        let fillStyle = undefined;
          
        if (vueSymbol.showFill !== undefined) {
          fillStyle = vueSymbol.showFill ? vueSymbol.style : "none";
        } else {
          fillStyle = vueSymbol.style === undefined ? undefined : vueSymbol.style
        }

        let outlineStyle = undefined;
        
        if (vueSymbol.showOutline !== undefined) {
          outlineStyle = vueSymbol.showOutline ? vueSymbol.outline.style : "none"
        } else {
          outlineStyle = (vueSymbol.outline && (vueSymbol.outline.style !== undefined)) ? vueSymbol.outline.style : undefined
        }                  
        
        symbol = {};
        symbol.type = vueSymbol.type;

        if (vueSymbol.text) symbol.text = vueSymbol.text;
        if (vueSymbol.size) symbol.size = vueSymbol.size;
        if (vueSymbol.width) symbol.width = vueSymbol.width;
        if (vueSymbol.color) symbol.color = vueSymbol.color;
        if (vueSymbol.angle) symbol.angle = vueSymbol.angle;
        if (vueSymbol.haloColor) symbol.haloColor = vueSymbol.haloColor;
        if (vueSymbol.haloSize) symbol.haloSize = vueSymbol.haloSize;
        if (vueSymbol.verticalAlignment) symbol.verticalAlignment = vueSymbol.verticalAlignment;
        if (vueSymbol.horizontalAlignment) symbol.horizontalAlignment = vueSymbol.horizontalAlignment;
        if (vueSymbol.lineHeight) symbol.lineHeight = vueSymbol.lineHeight;
        if (vueSymbol.lineWidth) symbol.lineWidth = vueSymbol.lineWidth;
        if (vueSymbol.kerning) symbol.kerning = vueSymbol.kerning;

        if (fillStyle) symbol.style = fillStyle;

        if (vueSymbol.outline) {
          symbol.outline = {};
          if (vueSymbol.outline.width) symbol.outline.width = vueSymbol.outline.width;
          if (vueSymbol.outline.color) symbol.outline.color = vueSymbol.outline.color;
          if (outlineStyle) symbol.outline.style = outlineStyle;
        }
            
        if (vueSymbol.font) {
          symbol.font = {};
          if (vueSymbol.font.style) symbol.font.style = vueSymbol.font.style;
          if (vueSymbol.font.size) symbol.font.size = vueSymbol.font.size;
          if (vueSymbol.font.family) symbol.font.family = vueSymbol.font.family;
          if (vueSymbol.font.weight) symbol.font.weight = vueSymbol.font.weight;
        }

        if (vueSymbol.marker) {
          symbol.marker = {};
          symbol.marker.color = vueSymbol.marker.color;
          symbol.marker.style = vueSymbol.marker.style;
          symbol.marker.placement = vueSymbol.marker.placement;          
        }
      }

      return symbol;
    },   
    
    copyVueFeatureToAgsFeature(vueFeature, agsFeature) {            

      if (vueFeature.type !== "identify") {
        // just symbol at the moment.
      
        let agsSymbol = this.vueSymbolToAgsSymbol(vueFeature.symbol);
        agsFeature.symbol = agsSymbol;

        if (vueFeature.line) {
          let lineFeature = vueFeature.line;
          agsSymbol = this.vueSymbolToAgsSymbol(lineFeature.symbol);
          lineFeature.agsFeature.symbol = agsSymbol;
        }
      }
    },  

    copyAgsFeatureToVueFeature(agsFeature, vueFeature) {      

      if (vueFeature.type !== "identify") {
        // just geometry (at the moment)
        let geometryTypeEx = vueFeature.geometry.typeEx;            
        vueFeature.geometry = agsFeature.geometry;            
        this.$set(vueFeature.geometry, "typeEx", geometryTypeEx);

        vueFeature.isInSelectionRange = false;
        if (this.selectionGeometry) {
          vueFeature.isInSelectionRange = this.geometryContainsOrIntersects(this.selectionGeometry, vueFeature.geometry);
        };      
      }
    },   

    useAsDefault(feature) {
      if (feature.type === "measurement") {
        this.setMeasurementDefaults(feature, this.defaults.measurement);                      
      }      

      if (feature.type === "userFeature") {
        this.setUserFeatureDefaults(feature, this.defaults.userFeature);                      
      }

      if (feature.type === "annotationEnd") {
        this.setAnnotationDefaults(feature.annotation, this.defaults.annotation);
      }
    },    

    updateUseAsDefaultEnabled() {
      if (this.selectedFeature) {     

        let newJSON;
        let oldJSON;
        let featureDefaults;

        if ((this.selectedFeature.type === "measurement") || (this.selectedFeature.type === "userFeature")) {

          oldJSON = JSON.stringify(this.defaults[this.selectedFeature.type]);
          featureDefaults = JSON.parse(oldJSON);                
          
          if (this.selectedFeature.type === "measurement") {
            this.setMeasurementDefaults(this.selectedFeature, featureDefaults);
          }

          if (this.selectedFeature.type === "userFeature") {
            this.setUserFeatureDefaults(this.selectedFeature, featureDefaults);
          }          
        } 
        
        if (this.selectedFeature.type === "annotationEnd") {
          oldJSON = JSON.stringify(this.defaults.annotation);
          featureDefaults = JSON.parse(oldJSON);                

          this.setAnnotationDefaults(this.selectedFeature.annotation, featureDefaults);
        }

        newJSON = JSON.stringify(featureDefaults);
        this.useAsDefaultFeatureEnabled = newJSON !== oldJSON;        
      }
    },        

    setAnnotationDefaults(annotation, defaults) {
      this.copySymbol(annotation.end.symbol, defaults.end.symbol);
      this.copySymbol(annotation.label.symbol, defaults.label.symbol);

      if (annotation.line) {
        this.copySymbol(annotation.line.symbol, defaults.line.symbol);
      }
    },

    setMeasurementDefaults(feature, defaults) {
            
      if (feature.geometry.typeEx === "polyline") {
        defaults.polyline.lengthVisibleOnMap = feature.lengthVisibleOnMap;
        defaults.polyline.lengthUnit = feature.lengthUnit;
        defaults.polyline.sideLengthsVisibleOnMap = feature.sideLengthsVisibleOnMap;
      }       

      if (feature.geometry.type === "polygon") {
        let locationVisibleOnMap = feature.locationVisibleOnMap;
        let lengthVisibleOnMap = feature.lengthVisibleOnMap;
        let lengthUnit = feature.lengthUnit;
        let sideLengthsVisibleOnMap = feature.sideLengthsVisibleOnMap;
        let areaVisibleOnMap = feature.areaVisibleOnMap;
        let areaUnit = feature.areaUnit;

        defaults.rectangle.locationVisibleOnMap = locationVisibleOnMap;
        defaults.rectangle.lengthVisibleOnMap = lengthVisibleOnMap;
        defaults.rectangle.lengthUnit = lengthUnit;
        defaults.rectangle.sideLengthsVisibleOnMap = sideLengthsVisibleOnMap;        
        defaults.rectangle.areaVisibleOnMap = areaVisibleOnMap;        
        defaults.rectangle.areaUnit = areaUnit;

        defaults.circle.locationVisibleOnMap = locationVisibleOnMap;
        defaults.circle.lengthVisibleOnMap = lengthVisibleOnMap;
        defaults.circle.lengthUnit = lengthUnit;
        defaults.circle.sideLengthsVisibleOnMap = sideLengthsVisibleOnMap;
        defaults.circle.areaVisibleOnMap = areaVisibleOnMap;        
        defaults.circle.areaUnit = areaUnit; 

        defaults.polygon.locationVisibleOnMap = locationVisibleOnMap;
        defaults.polygon.lengthVisibleOnMap = lengthVisibleOnMap;
        defaults.polygon.lengthUnit = lengthUnit;
        defaults.polygon.sideLengthsVisibleOnMap = sideLengthsVisibleOnMap;
        defaults.polygon.areaVisibleOnMap = areaVisibleOnMap;        
        defaults.polygon.areaUnit = areaUnit; 
      }      
      
      let allShapes = Object.values(defaults);
      allShapes.forEach(shape => {
        this.copySymbol(feature.symbol, shape.symbol);
      })
    },

    setUserFeatureDefaults(feature, defaults) {

      if (feature.geometry.typeEx === "point") {
        defaults.point.locationVisibleOnMap = feature.locationVisibleOnMap;        
        this.copySymbol(feature.symbol, defaults.point.symbol);
      }       
            
      if (feature.geometry.typeEx === "polyline") {
        defaults.polyline.lengthVisibleOnMap = feature.lengthVisibleOnMap;
        defaults.polyline.lengthUnit = feature.lengthUnit;
        defaults.polyline.sideLengthsVisibleOnMap = feature.sideLengthsVisibleOnMap;
        this.copySymbol(feature.symbol, defaults.polyline.symbol);
      }       

      if (feature.geometry.type === "polygon") {
        let locationVisibleOnMap = feature.locationVisibleOnMap;
        let lengthVisibleOnMap = feature.lengthVisibleOnMap;
        let lengthUnit = feature.lengthUnit;        
        let sideLengthsVisibleOnMap = feature.sideLengthsVisibleOnMap;
        let areaVisibleOnMap = feature.areaVisibleOnMap;
        let areaUnit = feature.areaUnit;

        defaults.rectangle.locationVisibleOnMap = locationVisibleOnMap;
        defaults.rectangle.lengthVisibleOnMap = lengthVisibleOnMap;
        defaults.rectangle.lengthUnit = lengthUnit;
        defaults.rectangle.sideLengthsVisibleOnMap = sideLengthsVisibleOnMap;       
        defaults.rectangle.areaVisibleOnMap = areaVisibleOnMap;         
        defaults.rectangle.areaUnit = areaUnit;         

        defaults.circle.locationVisibleOnMap = locationVisibleOnMap;
        defaults.circle.lengthVisibleOnMap = lengthVisibleOnMap;
        defaults.circle.lengthUnit = lengthUnit;
        defaults.circle.sideLengthsVisibleOnMap = sideLengthsVisibleOnMap;
        defaults.circle.areaVisibleOnMap = areaVisibleOnMap;         
        defaults.circle.areaUnit = areaUnit;         

        defaults.polygon.locationVisibleOnMap = locationVisibleOnMap;
        defaults.polygon.lengthVisibleOnMap = lengthVisibleOnMap;
        defaults.polygon.lengthUnit = lengthUnit;
        defaults.polygon.sideLengthsVisibleOnMap = sideLengthsVisibleOnMap;
        defaults.polygon.areaVisibleOnMap = areaVisibleOnMap;                 
        defaults.polygon.areaUnit = areaUnit;         

        this.copySymbol(feature.symbol, defaults.rectangle.symbol);
        this.copySymbol(feature.symbol, defaults.circle.symbol);
        this.copySymbol(feature.symbol, defaults.polygon.symbol);
      }              
    },

    copySymbol(from, to) {

      let fillColour;
      let fillStyle;
      let size;
      let lineColour;
      let lineWidth;
      let lineStyle;
      let textFontSize;
      let textColor;
      let textFontWeight;
      let textHaloColor;
      let textHaloSize

      if (from.type === "text") {
        textFontSize = from.font.size;
        textColor = from.color;
        textFontWeight = from.font.weight;
        textHaloColor = from.haloColor;
        textHaloSize = from.haloSize;                
      }

      if (from.type === "simple-marker") {
        fillColour = from.color;
        size = from.size;
        lineColour = from.outline.color;
        lineWidth = from.outline.width;  
        lineStyle = from.outline.style;      
      }

      if (from.type === "simple-line") {
        lineColour = from.color;
        lineWidth = from.width;      
        lineStyle = from.style;      
      }           

      if (from.type === "simple-fill") {
        fillColour = from.color;
        fillStyle = from.style;
        lineColour = from.outline.color;
        lineWidth = from.outline.width;        
        lineStyle = from.outline.style;           
      }             

      if (to.type === "simple-marker") {

        if (fillColour) {          
          let color = JSON.parse(JSON.stringify(fillColour));   
          to.color = color;
        }

        if (size) {
          to.size = size;
        }
                
        to.outline.color = JSON.parse(JSON.stringify(lineColour));              
        to.outline.style = lineStyle; 
      }

      if (to.type === "simple-line") {
        to.color = JSON.parse(JSON.stringify(lineColour));      
        to.width = lineWidth;
        to.style = lineStyle;
        if (to.marker) {
          to.marker.color = JSON.parse(JSON.stringify(lineColour));
        }
      }

      if (to.type === "simple-fill") {
        if (fillColour) {
          to.color = JSON.parse(JSON.stringify(fillColour));   
          if (fillStyle) {
            to.style = fillStyle;
          }
        }  

        to.outline.color = JSON.parse(JSON.stringify(lineColour));              
        to.outline.width = lineWidth; 
        to.outline.style = lineStyle; 
      }    

      if (to.type === "text") {
        to.font.size = textFontSize
        to.color =  JSON.parse(JSON.stringify(textColor));              
        to.font.weight = textFontWeight;
        to.haloColor = JSON.parse(JSON.stringify(textHaloColor));              
        to.haloSize = textHaloSize;
      }
    },    
  },  
  watch: {
    
    selectedFeature: {
      handler(newValue) {
        this.updateUseAsDefaultEnabled();        
      },
      deep: true
    },          

    defaults: {
      handler(newValue) {
        this.updateUseAsDefaultEnabled();        
      },
      deep: true
    },

    userFeatures: {      
      handler(value) {
      
        this.userFeatures.forEach(vueFeature => {
          this.copyVueFeatureToAgsFeature(vueFeature, vueFeature.agsFeature);
        })

        this.showFeatures(); 
      },
      deep: true
    },

    measurements: {
      handler(value) {
        this.measurements.forEach(vueFeature => {
          this.copyVueFeatureToAgsFeature(vueFeature, vueFeature.agsFeature);
        })

        this.showFeatures(); 
      },
      deep: true
    },
    
    annotations: {
      handler(value) {
        this.annotations.forEach(annotation => {
          this.copyVueFeatureToAgsFeature(annotation.end, annotation.end.agsFeature);
          this.copyVueFeatureToAgsFeature(annotation.label, annotation.label.agsFeature);
          if (annotation.line) {
            this.copyVueFeatureToAgsFeature(annotation.line, annotation.line.agsFeature);
          }
        })

        this.showFeatures(); 
      },
      deep: true
    },

    activePointerTool(newValue, oldValue) {                       
           
      if (this.activePointerTool === "identify" || this.activePointerTool === "zoomTo" ||this.activePointerTool === "createFeature" || this.activePointerTool === "measure" || this.activePointerTool === "annotate" || this.activePointerTool === "lineAnnotate") {
        this.create();   
      } else
      {
        this.createSvm.cancel();
      }      
    },      
    
    createFeatureGeometryType(newValue) {           
      this.geometryTypeChanged(newValue);
    },

    measureGeometryType(newValue) {      
      this.geometryTypeChanged(newValue);
    },    

    identifyGeometryType(newValue) {           
      this.geometryTypeChanged(newValue);
    },    
  
    transformableFeatures(newTransformables, oldTransformables) {
      //console.log("transformableFeatures, newValue: "+ newTransformables.length + ", oldValue: " + oldTransformables.length);
      
      if (this.updateSvm && !newTransformables.equals(oldTransformables)) {                    
        //console.log("completing")
        this.clearTransform = false;
        this.updateSvm.complete();           
        this.clearTransform = true;
        //console.log("updating")
        this.update(newTransformables);
      }           
    }
  }
};

export default createdFeatureSelection;

</script>