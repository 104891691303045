<template>  
  <div class="multi-button d-flex"          
  :class="{'align-items-end': facing === 'up' || facing === 'left', 'flex-column': facing === 'left' || facing === 'right'}"
  @focusout="focusOut">            
    <div 
    class="d-flex"
    :class="{'flex-column': facing === 'down', 'flex-column-reverse': facing === 'up', 'flex-row-reverse': facing === 'left'}">         
      <slot :name="activeButtonSlot" :onClose="onClose" 
      :borderTop="isInToolbar && (facing === 'down' || (facing === 'left' && isFirstChild()))"
      :borderRight="isInToolbar && (facing === 'left')"
      :borderBottom="isInToolbar && ((facing === 'down' && !open) || (facing === 'left' && isLastChild()))"
      :borderLeft="isInToolbar && facing === 'down' && isFirstChild()"      
      :height="(facing === 'down' || facing === 'up') && open ? height - 3 : height"
      :width="(facing === 'left' || facing === 'right') ? width - 3 : width"
      :popoverPlacement="activeButtonPopoverPlacement"      
      >
      </slot>                          
      <template v-if="open">           
        <template v-for="(buttonSlot, i) in nonActiveButtonSlots">          
          <slot :name="buttonSlot" :onClose="onClose"            
          :borderTop="isInToolbar && facing === 'left' || facing === 'right'"
          :borderRight="isInToolbar && facing === 'down' || facing === 'up'"          
          :borderBottom="isInToolbar && ((facing === 'down' && i === nonActiveButtonSlots.length - 1) || (facing ==='left'))"
          :borderLeft="isInToolbar && facing === 'down' || facing === 'up'"
          :height="height"
          :width="width"
          :popoverPlacement="nonActiveButtonPopoverPlacement"
          >      
          </slot>          
        </template>                          
      </template>           
      <template v-if="facing === 'left' || facing === 'right'">
      <Button            
      :borderTop="isInToolbar"      
      :borderBottom="isInToolbar"      
      :borderLeft="isInToolbar"      
      :height="48"
      :width="16"            
      @click="open = !open">
        <font-awesome-icon 
        :icon="caretIcon" transform="right-3"/>
      </Button>                 
    </template>  
    </div>       
    <template v-if="facing === 'down' || facing === 'up'">
      <Button            
      :borderTop="isInToolbar"
      :borderRight="isInToolbar && isLastChild()"
      :borderBottom="isInToolbar"      
      :height="48"
      :width="24"            
      @click="open = !open">
        <font-awesome-icon 
        :icon="caretIcon"/>
      </Button>                 
    </template>
  </div>     
</template>   

<script>

import Button from "./Button.vue"

export default {       
  name: "MultiButton",           
  components: { Button },  
  props: {    
    facing: {
      type: String,      
      Default: "down"
    },
    activeButtonSlot: {
      type: String,      
    },
    buttonSlots: {
      type: Array
    },
    height: {
      type: Number
    },
    width: {
      type: Number
    },
  },
  data() {
    return {            
      open: false,      
    }    
  },    
  computed: {
    activeButtonPopoverPlacement() {
      if (this.facing === 'down') {
        return this.open ? 'top' : 'bottom';
      }

      if (this.facing === 'left') {
        return 'top'
      }

      if (this.facing === 'up') {
        return this.open ? 'right' : 'top';
      }

      if (this.facing === 'right') {
        return this.open ? 'top' : 'right';
      }
    },

    nonActiveButtonPopoverPlacement() {
      if (this.facing === 'down') {
        return 'right';
      }

      if (this.facing === 'left') {
        return 'top';
      }

      if (this.facing === 'up') {
        return 'right';
      }

      if (this.facing === 'right') {
        return 'top';
      }
    },

    nonActiveButtonSlots() {
      return this.buttonSlots.filter(slot => slot !== this.activeButtonSlot);
    },

    caretIcon() {
      if (this.facing === 'down') 
        return this.open ? "caret-up" : "caret-down";

      if (this.facing === 'left') 
        return this.open ? "caret-right" : "caret-left";

      if (this.facing === 'up') 
        return this.open ? "caret-down" : "caret-up";

      if (this.facing === 'right') 
        return this.open ? "caret-left" : "caret-right";
    },   

    isInToolbar() {
      return this.$parent.$parent && this.$parent.$parent.$options.name === "Toolbar";      
    },
  },
  methods: {    

    isFirstChild() {      
      let toolbar = this.$parent;
      let children = toolbar.$children.filter(child => child.$options.name !== 'BVPopover');          
      return children[0] === this;      
    },

    isLastChild() {            
      let toolbar = this.$parent;            
      let children = toolbar.$children.filter(child => child.$options.name !== 'BVPopover');                      
      return children[children.length - 1] === this;      
    },

    onClose() {
      this.open = false;
    },  
    
    focusOut(focusEvent) {
      if (!focusEvent.currentTarget.contains(focusEvent.relatedTarget)) {
        this.onClose();
      }
    } 
  }
}

</script>