<template>    
  <div   
  class="bookmarkItem d-flex flex-column rounded pr-2"
  @mouseover="gotoHovered = true" 
  @mouseleave="gotoHovered = false"
  @click="gotoWithScale"
  >
    <div
    class="d-flex mt-2">
      <div
      class="d-flex flex-grow-1 align-items-center"
      >
        <img 
        class="thumbnail rounded-circle mx-2"  
        :src="bookmark.thumbnail.srcUrl"
        />         
        <span 
        class="flex-grow-1 name ml-1"
        :class="{'gotoHovered' : gotoHovered }"      
        >
          {{bookmark.name}}
        </span>
      </div>     
    </div>           
    <div class="d-flex ml-auto mb-2">
      <button       
      v-b-popover.hover.top="'Delete'" 
      @click.stop="$emit('deleteClicked')">
        <font-awesome-icon icon="trash-alt"/>
      </button>
      <button               
        class="d-flex align-items-center"     
        :class="{ selected: bookmark.visible }"  
        v-b-popover.hover.top="'Show on map'" 
        @click.stop="bookmark.visible = !bookmark.visible">        
          <font-awesome-icon transform="grow-2" class="eyeIcon" :icon="bookmark.visible ? 'eye' : ['far', 'eye']"/>        
        </button>         
      <button
       class="d-flex align-items-center"                
      v-b-popover.hover.top="'Goto (retain current scale)'" 
      @click.stop="goto">        
        <font-awesome-icon :icon="['far', 'dot-circle']"/>        
      </button>           
    </div>
  </div>                            
</template>

<script>

import { agsPointToCoordString } from "./SpatialUtils.js";

export default {  
	props: { 		
    coordinateMode:{
      type:String,
      required:true
    },  
    bookmark: {
      type: Object,
      required: true
    },        
  },      	
	data() {
		return {		
      gotoHovered: false,  
		}
  },	   
  computed: {
    coordString()
    {
      return agsPointToCoordString(this.bookmark.coords, this.coordinateMode);
    },        
  },			  
  methods: {    
    goto() {                        
      this.$emit("goto", { target:  this.bookmark.coords, featureId: this.bookmark.featureId });
    },
    gotoWithScale() {    
      this.$emit("goto", { target:  this.bookmark.coords, scale: this.bookmark.scale, featureId: this.bookmark.featureId });      
    }
  },		 
}    
</script>

<style lang="scss" scoped>

@import "../variables";

.bookmarkItem {  

  background-color: inherit;
  color: $textColour;
  border-bottom: 1px solid $panelColourSeperator;
  cursor: pointer;  

  .thumbnail {
    box-shadow: 0 0 0 6px rgba(10, 10, 10, 0.125);
  }

  .gotoHovered {
    color: $textColourHover !important;
  }

  &:hover {
    background-color: $panelColourHover;
  }

  .name {    
    font-size: $fontSizeLg;    
    color: $textColourLight;
    overflow: hidden; 
    text-overflow: ellipsis;  
    
  }

  .property {
    font-size: $fontSizeSm;    
    color:#929496;
  }
}

</style>