<template>
  <div class="mapNavigation">
    <button @click="$emit('zoom', false)">
      <font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
    </button>
    <button @click="$emit('zoom', true)">
      <font-awesome-icon :icon="['fas', 'minus']"></font-awesome-icon>
    </button>
  </div>
</template>

<script>
export default {
};
</script>

<style  lang="scss" scoped>

@import "././../variables";

.mapNavigation {
  //position: absolute;
  // top: 120px;
  // right: 40px;
  
  box-shadow: 0 0 0 3px rgba(10, 10, 10, 0.2);
}
.mapNavigation button {  
  background: $panelColour;
  border: none;
  color: $textColour;
  display: block;
  font-size: 12px;
  width:40px;
  height:40px;
  cursor: pointer;
}
.mapNavigation button:not(:last-child) {
  margin-bottom: 0px;
  border-bottom: 1px solid $panelColourSeperator
}
.mapNavigation button:hover {
  color: $textColourHover;
}
</style>
