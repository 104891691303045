<template>  
  <div class="toolbar">
    <Button                              
    :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
    v-b-popover.hover="{ content: 'Previous selected item', placement: facing === 'down' ? 'bottom' : facing === 'up' ? 'top' : facing === 'left' ? 'left' : 'right'}"              
    :enabled="previousEnabled"
    :height="48"
    :width="48"        
    @click="previousItem">                      
      <font-awesome-icon transform="grow-6" icon="arrow-left"/>
    </Button>                 
    <Seperator :orientation="facing === 'left' || facing === 'right' ? 'horizontal' : 'vertical'"  :size="48"/>
    <Button                              
    :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
    v-b-popover.hover="{ content: 'Next selected item', placement: facing === 'down' ? 'bottom' : facing === 'up' ? 'top' : facing === 'left' ? 'left' : 'right'}"
    :enabled="nextEnabled"
    :height="48"
    :width="48"        
    @click="nextItem">                      
    <font-awesome-icon transform="grow-6" icon="arrow-right"/>                                    
    </Button>                    
  </div>
</template> 

<script>

import Button from "./shared/Toolbar/Button.vue"
import Seperator from "./shared/Toolbar/Seperator.vue";

export default {               
  components: { Button, Seperator },  
  props: {
    previousEnabled: {
      type: Boolean,      
    },
    nextEnabled: {
      type: Boolean,
    },
    facing: {
      type: String,
      default: "down"            
    }
  },
  methods: {
    previousItem() {
      if (this.previousEnabled) {
        this.$emit("previousItem");
      }      
    },

    nextItem() {
      if (this.nextEnabled) {
        this.$emit("nextItem");
      }      
    }
  }
}

</script>