<template>    
    <div id="sliderHeader" class="d-flex align-items-center mx-2 flex-shrink-0">           
      <div :class="{ 'mr-2' : $slots['prepend'] }">
        <slot name="prepend"></slot>        
      </div>
      <div class="text">{{text}}</div>        
      <div class="d-flex">
        <slot name="appendAlignedToLeft"></slot>
      </div>
      <div class="ml-auto d-flex">        
        <slot name="appendAlignedToRight"></slot>
        <template v-if="$slots.appendAlignedToRight">       
          <div class="mx-2 seperator"></div>
        </template>
        <button v-if="showHelp"
          @click="$emit('showHelp')"
          class="d-flex align-items-center">
          ? &nbsp;
        </button>   
        <button 
          class="d-flex align-items-center" 
          @click="$emit('closeMenuItem', menuName)">
          <font-awesome-icon :icon="['fal', 'times']" transform="grow-4"/>
        </button>   
      </div>     
    </div>
</template>

<script>

export default {
    name: 'SliderHeader',    
    props: {
      text: {
        type: String,
        required: true
      },
      menuName: {
        type: String,
        required: true
      },       
      showHelp:{
        type:Boolean,
        default: true
      } 
    }      
}    
</script>

<style lang="scss" scoped>
@import "../../variables";

#sliderHeader {      

  position:relative;
  user-select: none;

  .seperator {
    width: 0px;
    border-width: 1px;    
    border-color: $controlSeperator;
    border-style: none none none solid;
  };

  height: 48px;            
  color:$textColourLight;            
  font-size: $fontSizeXlg;
  background-color: $panelColour;  

  button {    
    color: $textColour;
    background-color: inherit;
    border: none;

    &.selected {
      color: $textColourHover;
    }   

    &:hover{
      color:$textColourHover;
    }
  }
}   

</style>