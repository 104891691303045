<template>
  <button        
    :style="buttonStyle"     
    :class="{contentsSelected: isSelected, inactive: !enabled, borderTop: activeBorderTop, borderRight: activeBorderRight, borderBottom: activeBorderBottom, borderLeft: activeBorderLeft}"
    class="button d-flex align-items-center justify-content-center p-0 position-relative pointer-events-auto"
    @click="buttonClicked">
      <div 
      class="buttonSlice selected"
      :style="selectionStyle"
      v-show="isSelected"/>
      <template v-if="$slots.default">
        <slot></slot>
      </template>    
  </button>        
</template>

<script>

export default {       
  name: "Button",     
  props: {
    enabled: {
      default: true,
    },  
    selected: {
      type: Boolean      
    },        
    height: {
      required: true,
      type: Number,            
    },
    width: {
      required: true,
      type: Number,      
    },
    borderTop: {
      Type: Boolean      
    },
    borderRight: {
      Type: Boolean      
    },
    borderBottom: {
      Type: Boolean      
    },
    borderLeft: {
      Type: Boolean      
    },
    selectionSide: {
      type: String,
      default: 'top'
    }
  }, 
  data() {
    return {
      activeBorderTop: false,
      activeBorderLeft: false,
      activeBorderRight: false,      
      activeBorderBottom: false,      
    }
  },
  created() {
    this.activeBorderTop = this.borderTop;
    this.activeBorderBottom = this.borderBottom;    
    this.activeBorderRight = this.borderRight;
    this.activeBorderLeft = this.borderLeft;
  },
  watch: {
    borderTop(value) {
      this.activeBorderTop = value;
    },
    borderRight(value) {
      this.activeBorderRight = value;
    },
    borderBottom(value) {
      this.activeBorderBottom = value;
    },    
    borderLeft(value) {
      this.activeBorderLeft = value;
    },
  },
  mounted() {        

    console.log(this.$parent)
    console.log(this.$parent.$options.name)
    console.log(this.$parent.$parent);
    console.log(this.$parent.$parent.$options.name);
    
    if (this.$parent.$parent && this.$parent.$parent.$options.name === "Toolbar") {      

      this.activeBorderTop = false;
      this.activeBorderRight = false;
      this.activeBorderBottom = false;
      this.activeBorderLeft = false;

      let toolbar = this.$parent;                
      let facing = toolbar._props.facing;      

      let children = toolbar.$children.filter(child => child.$options.name !== 'BVPopover');    
      let index = children.indexOf(this);      
      
      if ((facing === "down" || facing === "up") ||
          (facing === "left" || facing === "right") && (index === 0)) {
        this.activeBorderTop = true;
      }

      if (((facing === "down" || facing === "up") && (index === children.length - 1)) ||
          (facing === "left" || facing === "right")) {
        this.activeBorderRight = true;
      }

      if ((facing === "down" || facing === "up") ||
          (facing === "left" || facing === "right") && (index === children.length - 1)) {
        this.activeBorderBottom = true;
      }

      if (((facing === "down" || facing === "up") && (index === 0)) ||
          (facing === "left" || facing === "right")) {
        this.activeBorderLeft = true;
      }           
    }     
  },
  computed: {
    isSelected() {
       if (this.subMenuName) {
         return this.menuItems[this.subMenuName];
       } else {
         return this.selected;
       }
    },
    buttonStyle()
    {
      return {
        width: this.width + "px",
        height: this.height + "px"
      }
    },
    selectionStyle()
    {
      return {
        height: (this.selectionSide === "left" || this.selectionSide === "right") ? "100%" : "2px",
        width: (this.selectionSide === "left" || this.selectionSide === "right") ? "2px" : "100%",
        top: (this.selectionSide !== "bottom") ? "0px": undefined,
        right: (this.selectionSide === "right") ? "0px": undefined,
        bottom: (this.selectionSide === "bottom") ? "0px" : undefined,
        left: (this.selectionSide !== "right") ? "0px" : undefined,        
      }
    },  
  },
  methods: {
    buttonClicked(clickEvent) {

      if (this.enabled) {                
        this.$emit("click", clickEvent);
      }
    }    
  }
}

</script>

<style lang="scss" scoped>
@import "../../../variables";

button
{  
  font-size: 16px;
  border-width: 2px;
  border: none;
  border-color: rgba(10, 10, 10, .2);
  color: $textColour;
  background:$panelColour;  
  background-clip: padding-box;

  &.borderLeft {    
    border-left-style: solid;
  }

  &.borderTop {
    border-top-style: solid;
  }

  &.borderRight {
    border-right-style: solid;
  }

  &.borderBottom {
    border-bottom-style: solid;
  }

  &:hover {        
    color: $textColourHover
  }  

  &.contentsSelected 
  {
    svg {
      color: $pColourDark;
    }
  }

  &.inactive
  {
    svg {
      color: $pColourDarkest;
    }
  }

  .buttonSlice {  
    background-color: $pColourDark;
    position: absolute;  
  }

  .selected {  
    color: $pColourDark;
  }
}

</style>