<template>  
  <div 
  :style="seperatorStyle" 
  :class="{borderTop: borderTop, borderRight: borderRight, borderBottom: borderBottom, borderLeft: borderLeft}"
  class="seperator d-flex">
    <div 
    class="seperator-inner"
    :style="seperatorInnerStyle">
    </div>
  </div>
</template>

<script>

export default {       
  props: {   
    name: "Seperator",     
    size: {
      required: true,
      type: Number,      
    },
    orientation: {
      type: String,
      default: "horizontal"
    }
  },
  data() {
    return {
      borderTop: false,
      borderLeft: false,
      borderRight: false,      
      borderBottom: false,      
    }
  },
  mounted() {    

    if (this.$parent.$parent && this.$parent.$parent.$options.name == "Toolbar") {      

      this.borderTop = false;
      this.borderRight = false;
      this.borderBottom = false;
      this.borderLeft = false;

      let toolbar = this.$parent;                
      let facing = toolbar._props.facing;      

      let children = toolbar.$children.filter(child => child.$options.name !== 'BVPopover');    
      let index = children.indexOf(this);      
      
      if ((facing === "down" || facing === "up") ||
          (facing === "left" || facing === "right") && (index === 0)) {
        this.borderTop = true;
      }

      if (((facing === "down" || facing === "up") && (index === children.length - 1)) ||
          (facing === "left" || facing === "right")) {
        this.borderRight = true;
      }

      if ((facing === "down" || facing === "up") ||
          (facing === "left" || facing === "right") && (index === children.length - 1)) {
        this.borderBottom = true;
      }

      if (((facing === "down" || facing === "up") && (index === 0)) ||
          (facing === "left" || facing === "right")) {
        this.borderLeft = true;
      }           
    }
  },
  computed: {
    seperatorStyle() {
      
      if (this.orientation === "horizontal") {

        return {
          'width': this.size + 'px',
          'justifyContent': 'center'
        }                   
      }             
      
      return {
          'height': this.size + 'px',
          'alignItems': 'center'
        }                          
    },

    seperatorInnerStyle() {

      if (this.orientation === "horizontal") {
        return {          
          'height': "1px",
          'width': this.size * 0.65 + 'px',
      }                        
      }             

      return {
          'height': this.size * 0.65 + 'px',
          'width': "1px"
        }       
           
    }
  }
}

</script>

<style lang="scss">
@import "../../../variables";

.seperator {                              
  
  background:$panelColour;
  border: none;
  border-color: rgba(10, 10, 10, .2);
  background-clip: padding-box;

  &.borderLeft {    
    border-left-style: solid;
  }

  &.borderTop {
    border-top-style: solid;
  }

  &.borderRight {
    border-right-style: solid;
  }

  &.borderBottom {
    border-bottom-style: solid;
  }
     
  .seperator-inner {
    background-color: $controlSeperator;    
  }
};  



</style>