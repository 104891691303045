<template>  
  <div class="toolbar">
    <MultiButton    
    :facing="facing"
    :activeButtonSlot="activeDeleteAll"        
    :buttonSlots="['all', 'inView']"
    :height="48"
    :width="48"    
    >
    <template v-slot:all="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button               
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"                       
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"
        v-b-popover.hover="{content: deleteAllToolTip('all'), placement: popoverPlacement}"        
        :height="height"
        :width="width"        
        @click="$emit('deleteAll', 'all'); $emit('activeDeleteAllChanged', 'all'); onClose()">                      
          <font-awesome-icon icon="trash-alt"/>          
      </Button>               
      </template>
      <template v-slot:inView="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button                 
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"             
        v-b-popover.hover="{content: deleteAllToolTip('inView'), placement: popoverPlacement}"                 
        :height="height"
        :width="width"        
        @click="$emit('deleteAll', 'inView'); $emit('activeDeleteAllChanged', 'inView'); onClose()">                      
        <font-awesome-layers>                   
          <font-awesome-icon icon="trash-alt" transform="left-4"/> 
          <font-awesome-icon :icon="['far', 'eye']" transform="right-8 up-6 shrink-3"/>          
        </font-awesome-layers>    
        </Button>          
      </template>
    </MultiButton>   
    <Seperator :orientation="facing === 'left' || facing === 'right' ? 'horizontal' : 'vertical'"  :size="48"/>
    <Button                 
    :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
    v-b-popover.hover="{ content: 'Undo last delete', placement: facing === 'down' ? 'bottom' : facing === 'up' ? 'top' : facing === 'left' ? 'left' : 'right'}"                                                
    :enabled="canUndoDelete"
    :height="48"
    :width="48"        
    @click="$emit('undoDelete')">
      <font-awesome-icon :icon="['fas', 'arrow-rotate-left']" transform="grow-6"/>          
    </Button>                                  
  </div>     
</template>   

<script>

import Button from "./shared/Toolbar/Button.vue"
import MultiButton from "./shared/Toolbar/MultiButton.vue"
import Seperator from "./shared/Toolbar/Seperator.vue"

export default {                 
  components: { Button, MultiButton, Seperator },  
  props: {
    facing: {
      type: String,
      default: "down"            
    },
    activeDeleteAll: {
      type: String,      
    },
    canUndoDelete: {
      Type: Boolean
    }
  },  
  methods: {
  
    deleteAllToolTip(selectType) {
      return selectType === 'inView' ? 'Delete all drawn items (in view only)' : 
                                        'Delete all drawn items (entire map)';
    },        
  }
}

</script>
