<template>  
  <div class="toolbar">    
    <MultiButton    
    :facing="facing"
    :activeButtonSlot="identifyShape"        
    :buttonSlots="['rectangle', 'circle', 'polygon', 'polyline', 'point']"    
    :height="48"
    :width="48"    
    >
      <template v-slot:rectangle="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button     
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"                                 
        v-b-popover.hover="{content: multiSelectToolTip('rectangle'), placement: popoverPlacement}"     
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'identify' && identifyShape === 'rectangle'"    
        @click="identifyShapeChanged('rectangle'); onClose()">                  
          <font-awesome-layers>
            <font-awesome-icon icon="mouse-pointer" transform="right-10 grow-1"/>
            <font-awesome-icon :icon="['far', 'square']" transform="shrink-6 left-12 up-6"/>       
          </font-awesome-layers>  
        </Button>
      </template>      

      <template v-slot:circle="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement }">
        <Button                 
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"             
        v-b-popover.hover="{content: multiSelectToolTip('circle'), placement: popoverPlacement}"     
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'identify' && identifyShape === 'circle'"    
        @click="identifyShapeChanged('circle'); onClose()">                  
          <font-awesome-layers>
            <font-awesome-icon icon="mouse-pointer" transform="right-10 grow-1"/>
            <font-awesome-icon :icon="['far', 'circle']" transform="shrink-6 left-12 up-6"/>       
          </font-awesome-layers>  
        </Button>
      </template>      

      <template v-slot:polygon="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"             
        v-b-popover.hover="{content: multiSelectToolTip('polygon'), placement: popoverPlacement}"     
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'identify' && identifyShape === 'polygon'"    
        @click="identifyShapeChanged('polygon'); onClose()">                  
          <font-awesome-layers>
            <font-awesome-icon icon="mouse-pointer" transform="right-10 grow-1"/>
            <font-awesome-icon :icon="['fal', 'draw-polygon']" transform="shrink-6 left-12 up-6"/>       
          </font-awesome-layers>  
        </Button>
      </template>      

      <template v-slot:polyline="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button                              
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"
        v-b-popover.hover="{content: multiSelectToolTip('polyline'), placement: popoverPlacement}"     
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'identify' && identifyShape === 'polyline'"    
        @click="identifyShapeChanged('polyline'); onClose()">                  
          <font-awesome-layers>
            <font-awesome-icon icon="mouse-pointer" transform="right-10 grow-1"/>
            <font-awesome-icon :icon="['fas', 'wave-sine']" transform="shrink-6 left-12 up-6"/>       
          </font-awesome-layers>  
        </Button>
      </template>      

      <template v-slot:point="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button                 
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"             
        v-b-popover.hover="{content: multiSelectToolTip('point'), placement: popoverPlacement}"     
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'identify' && identifyShape === 'point'"           
        @click="identifyShapeChanged('point'); onClose()">
        <font-awesome-layers>
          <font-awesome-icon icon="mouse-pointer" transform="right-10 grow-1"/>
          <font-awesome-icon :icon="['fas', 'circle']"  transform="shrink-6 left-12 up-6"/>       
        </font-awesome-layers>  
        </Button>
      </template>
    </MultiButton>  
    <Seperator :orientation="facing === 'left' || facing === 'right' ? 'horizontal' : 'vertical'"  :size="48"/>    
    <MultiButton    
    :facing="facing"
    :activeButtonSlot="measureShape"        
    :buttonSlots="['rectangle', 'circle', 'polygon', 'polyline', 'point']"    
    :height="48"
    :width="48"    
    >
      <template v-slot:rectangle="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button     
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"  
        v-b-popover.hover="{content: measureToolTip('rectangle'), placement: popoverPlacement}"                                               
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'measure' && measureShape === 'rectangle'"   
        @click="measureShapeChanged('rectangle'); onClose()">                  
          <font-awesome-layers>
            <font-awesome-icon :icon="['fas', 'ruler-vertical']" transform="right-10 grow-1"/>
            <font-awesome-icon :icon="['far', 'square']" transform="shrink-6 left-12 up-6"/>     
          </font-awesome-layers>  
        </Button>
      </template>      

      <template v-slot:circle="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button     
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"                                    
        v-b-popover.hover="{content: measureToolTip('circle'), placement: popoverPlacement}"                                                   
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'measure' && measureShape === 'circle'"   
        @click="measureShapeChanged('circle'); onClose()">                  
          <font-awesome-layers>
            <font-awesome-icon :icon="['fas', 'ruler-vertical']" transform="right-10 grow-1"/>
            <font-awesome-icon :icon="['far', 'circle']" transform="shrink-6 left-12 up-6"/>
          </font-awesome-layers>  
        </Button>
      </template>      

      <template v-slot:polygon="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button     
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"                                
        v-b-popover.hover="{content: measureToolTip('polygon'), placement: popoverPlacement}"                                                   
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'measure' && measureShape === 'polygon'"   
        @click="measureShapeChanged('polygon'); onClose()">                  
          <font-awesome-layers>
            <font-awesome-icon :icon="['fas', 'ruler-vertical']" transform="right-10 grow-1"/>
            <font-awesome-icon :icon="['fal', 'draw-polygon']" transform="shrink-6 left-12 up-6"/>
          </font-awesome-layers>  
        </Button>
      </template>      

      <template v-slot:polyline="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button     
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"                                    
        v-b-popover.hover="{content: measureToolTip('polyline'), placement: popoverPlacement}"
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'measure' && measureShape === 'polyline'"          
        @click="measureShapeChanged('polyline'); onClose()">                  
          <font-awesome-layers>
            <font-awesome-icon :icon="['fas', 'ruler-vertical']" transform="right-10 grow-1"/>
            <font-awesome-icon :icon="['fas', 'wave-sine']" transform="shrink-6 left-12 up-6"/>
          </font-awesome-layers>  
        </Button>
      </template>      

      <template v-slot:point="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button     
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"                                    
        v-b-popover.hover="{content: measureToolTip('point'), placement: popoverPlacement}"        
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'measure' && measureShape === 'point'"          
        @click="measureShapeChanged('point'); onClose()">
        <font-awesome-layers>
          <font-awesome-icon :icon="['fas', 'ruler-vertical']" transform="right-10 grow-1"/>
          <font-awesome-icon :icon="['fas', 'circle']" transform="shrink-6 left-12 up-6"/>       
        </font-awesome-layers>  
        </Button>
      </template>

    </MultiButton>                   

    <Seperator :orientation="facing === 'left' || facing === 'right' ? 'horizontal' : 'vertical'"  :size="48"/>    

    <Button                   
    :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"           
    v-b-popover.hover="{ content: 'Tag label', placement: facing === 'down' ? 'bottom' : facing === 'up' ? 'top' : facing === 'left' ? 'left' : 'right'}"                               
    :height="48"
    :width="48"        
    :selected="activePointerTool === 'lineAnnotate'"    
    @click="$emit('activePointerToolChanged', 'lineAnnotate')">      
      <font-awesome-layers>
        <font-awesome-icon :icon="['fas', 'wave-sine']" flip="horizontal" transform="shrink-4 up-4 left-13"/>
        <font-awesome-icon  :icon="['far', 'font']" transform="grow-2 right-2"/>            
      </font-awesome-layers>
    </Button>      

    <Seperator :orientation="facing === 'left' || facing === 'right' ? 'horizontal' : 'vertical'"  :size="48"/>    

    <Button                              
    :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
    v-b-popover.hover="{ content: 'Label', placement: facing === 'down' ? 'bottom' : facing === 'up' ? 'top' : facing === 'left' ? 'left' : 'right'}"                               
    :height="48"
    :width="48"        
    :selected="activePointerTool === 'annotate'"    
    @click="$emit('activePointerToolChanged', 'annotate')">      
      <font-awesome-icon  :icon="['far', 'font']" transform="grow-2"/>            
    </Button>      

    <Seperator :orientation="facing === 'left' || facing === 'right' ? 'horizontal' : 'vertical'"  :size="48"/>    

    <MultiButton    
    :facing="facing"
    :activeButtonSlot="createFeatureShape"        
    :buttonSlots="['rectangle', 'circle', 'polygon', 'polyline', 'point']"    
    :height="48"
    :width="48"    
    >
      <template v-slot:rectangle="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button     
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"             
        v-b-popover.hover="{content: createFeatureToolTip('rectangle'), placement: popoverPlacement}"          
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'createFeature' && createFeatureShape === 'rectangle'"   
        @click="createShapeChanged('rectangle'); onClose()">                  
          <font-awesome-icon :icon="['far', 'square']" transform="grow-3"/>
        </Button>
      </template>      

      <template v-slot:circle="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button     
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"                                    
        v-b-popover.hover="{content: createFeatureToolTip('circle'), placement: popoverPlacement}"
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'createFeature' && createFeatureShape === 'circle'"   
        @click="createShapeChanged('circle'); onClose()">                  
          <font-awesome-icon :icon="['far', 'circle']" transform="grow-3"/>
        </Button>
      </template>      

      <template v-slot:polygon="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button     
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"                                    
        v-b-popover.hover="{content: createFeatureToolTip('polygon'), placement: popoverPlacement}"        
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'createFeature' && createFeatureShape === 'polygon'"   
        @click="createShapeChanged('polygon'); onClose()">                  
          <font-awesome-icon :icon="['fal', 'draw-polygon']" transform="right-2 grow-5"/>
        </Button>
      </template>      

      <template v-slot:polyline="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button     
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"                  
        v-b-popover.hover="{content: createFeatureToolTip('polyline'), placement: popoverPlacement}"                
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'createFeature' && createFeatureShape === 'polyline'"          
        @click="createShapeChanged('polyline'); onClose()">                  
          <font-awesome-icon :icon="['fas', 'wave-sine']" transform="grow-3"/>
        </Button>
      </template>      

      <template v-slot:point="{ onClose, borderTop, borderRight, borderBottom, borderLeft, height, width, popoverPlacement}">
        <Button     
        :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
        :borderTop="borderTop"
        :borderRight="borderRight"
        :borderBottom="borderBottom"
        :borderLeft="borderLeft"             
        v-b-popover.hover="{content: createFeatureToolTip('point'), placement: popoverPlacement}"                                       
        :height="height"
        :width="width"        
        :selected="activePointerTool === 'createFeature' && createFeatureShape === 'point'"          
        @click="createShapeChanged('point'); onClose()">
          <font-awesome-icon :icon="['fas', 'circle']" transform="shrink-3"/>       
        </Button>
      </template>

    </MultiButton>                   

  </div>     
</template>   

<script>

import Button from "./shared/Toolbar/Button.vue"
import MultiButton from "./shared/Toolbar/MultiButton.vue"
import Seperator from "./shared/Toolbar/Seperator.vue"

export default {                 
  components: { Button, MultiButton, Seperator },  
  props: {
    facing: {
      type: String,
      default: "down"            
    },
    activePointerTool: {
      type: String,      
    },   
    measureShape: {
      type: String,
    },
    createFeatureShape: {
      type: String
    },
    identifyShape: {
      type: String,
    },    
  },  
  methods: {    

    multiSelectToolTip(shape) {
      let toolTip = "Identify features ";   
      
      if (shape === "rectangle") {
        toolTip += "in rectangle";
      }
      
      if (shape === "circle") {
        toolTip += "in circle";
      }

      if (shape === "polygon") {
        toolTip += "in polygon";
      }

      if (shape === "polyline") {
        toolTip += "on line";
      }

      if (shape === "point") {
        toolTip += "at point";
      }

      return toolTip;
    },

    identifyShapeChanged(shape) {
      this.$emit('identifyShapeChanged', shape);
      this.$emit('activePointerToolChanged', "identify");      
    },    
   
    measureToolTip(shape) {
      let tooltip = "Create ";

      if (shape == "point") {
        tooltip += "point"
      } 

      if (shape == "polyline") {
        tooltip += "line"
      } 

      if (shape === "rectangle") {
        tooltip += "rectangular"
      }

      if (shape === "circle") {
        tooltip += "circular"
      }

      if (shape === "polygon") {
        tooltip += "polygon"
      }

      return tooltip += " measurement"
    },

    measureShapeChanged(shape) {
      this.$emit('measureShapeChanged', shape);
      this.$emit('activePointerToolChanged', "measure");      
    },

    createFeatureToolTip(shape) {
      let tooltip = "Create ";

      if (shape == "point") {
        tooltip += "point"
      } 

      if (shape == "polyline") {
        tooltip += "line"
      } 

      if (shape === "rectangle") {
        tooltip += "rectangular"
      }

      if (shape === "circle") {
        tooltip += "circular"
      }

      if (shape === "polygon") {
        tooltip += "polygon"
      }

      return tooltip += " feature"
    },    

    createShapeChanged(shape) {
      this.$emit('createFeatureShapeChanged', shape);
      this.$emit('activePointerToolChanged', "createFeature");      
    },
  }
}

</script>