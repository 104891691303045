<template>  
  <div class="toolbar">

    <Button     
    :selectionSide="'top'"
    v-b-popover.hover="{ content: 'Navigate and select', placement: facing === 'down' ? 'bottom' : facing === 'up' ? 'top' : facing === 'left' ? 'left' : 'right'}"              
    :height="48"
    :width="48"        
    :selected="activePointerTool === 'defaultTool'"    
    @click="$emit('activePointerToolChanged', 'defaultTool')">      
      <font-awesome-icon icon="mouse-pointer" transform="grow-1"/>
    </Button>                                  

    <Seperator :orientation="facing === 'left' || facing === 'right' ? 'horizontal' : 'vertical'"  :size="48"/>

    <Button                              
    :selectionSide="facing === 'down' ? 'top' : facing === 'left' ? 'right' : facing === 'up' ? 'bottom' : 'left'"
    v-b-popover.hover="{ content: 'Zoom to rectangle', placement: facing === 'down' ? 'bottom' : facing === 'up' ? 'top' : facing === 'left' ? 'left' : 'right'}"              
    :height="48"
    :width="48"        
    :selected="activePointerTool === 'zoomTo'"    
    @click="$emit('activePointerToolChanged', 'zoomTo')">           
    <font-awesome-layers>
      <font-awesome-icon :icon="['fal', 'square-dashed']" transform="grow-6 left-6"/>          
        <font-awesome-icon icon="search" transform="right-4 down-6 grow-1"/>                      
      </font-awesome-layers>
    </Button>                                 
                                  
  </div>     
</template>   

<script>

import Button from "./shared/Toolbar/Button.vue"
import MultiButton from "./shared/Toolbar/MultiButton.vue"
import Seperator from "./shared/Toolbar/Seperator.vue"

export default {                 
  components: { Button, MultiButton, Seperator },  
  props: {
    facing: {
      type: String,
      default: "down"            
    },
    activePointerTool: {
      type: String,      
    },    
  },    
}

</script>
