<template>    
  <portal :to="'menus/' + menuName">       
    <div class="settings h-100 d-flex flex-column">
      <SliderHeader 
      text="SETTINGS" 
      class="mb-2"
      :menuName="menuName"
      @openMenuItem="$emit('openMenuItem', $event)" 
      @closeMenuItem="$emit('closeMenuItem', $event)">
      </SliderHeader>     
      <span class="ml-3">Feature and Measurement Labels</span>
      <SymbolProperties                 
      noAngle 
      noOpacity
      noText      
      noSize
      class="flex-grow-1 mx-3"                   
      :symbol="featuresLabelSymbol">
      </SymbolProperties>
    </div>                               
  </portal>
</template>

<script>

import SliderHeader from './shared/SliderHeader.vue';
import SymbolProperties from './SymbolProperties.vue'; 

export default {
  components: { SliderHeader, SymbolProperties},  
  props:
  {  
    featuresLabelSymbol: {
      type: Object
    }
  },
  data() {
    return {
      menuName: "settings"
    }
  }
}    
</script>

<style lang="scss" scoped>

@import "../variables";

.settings {
  user-select: none;
  color: $textColour;
  background-color: $panelColour;  

  font-size: $fontSize;   
}

</style>